*, ::before, ::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

// html,
// body {
//   background: black;
// }

.page {
  transition: 0.35s;
}

.header {
  z-index: 9999;

  .uk-navbar-container {
    &:not(.uk-navbar-transparent) {
      background: transparent;
    }
  }

  // &:hover {
  //   + .page {
  //     opacity: 0.6;
  //   }
  // }
}

.lf-brand {
  a{
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: opacity .3s ease;
    &:hover{
      text-decoration: none;
      opacity: 0.6;
    }
  }
  margin: 6px 0 0;
  padding: 18px 0;
  
  h3{
    margin:0px;
    display: inline-flex;
    align-self:flex-end;
    font-size:1.25em;
    color:#B1B1B1;
    text-transform: uppercase;
    font-family:$fontstack-condensed;
    text-decoration: none;
    letter-spacing: 1px;
    align-items:flex-end;
    .lf-divider{
      width:1px;
      height: 50px;
      background-color: #B1B1B1;
      margin: 0px 25px;
    }
  }
  svg {
    path,
    polygon {
      transition: 0.25s;
    }
  }

  .lf-brand-svg--full {
    width: 90px;
    height: 50px;

    @media (min-width: $breakpoint-medium) {
      width: 160px;
      height: 90px;
    }
  }

  .lf-brand-svg--mini {
    width: 130px;
    height: 38px;
  }

  @media (min-width: $breakpoint-small) {
    margin: 0;
    padding: 15px 0;
  }

  @media (min-width: $breakpoint-medium) {
    margin: 0;
    padding: 30px 0;
  }
}

.header-container {
  & > div > div {
    @media (max-width: $breakpoint-small-max) {
      height: 80px;
    }
    // .lf-brand svg {
    //   @media (max-width: $breakpoint-small-max) {
    //     height: 50px;
    //     width: 90px;
    //   }
    // }
  }
}


.header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: color(white);
  transition: 0.25s;
  opacity: 1;
  pointer-events: none;
  // transform: translateY(-50%);
}

// .header-shadow {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 90%);
//   pointer-events: none;
// }

.header-navbar {
  transition: 0.25s;
  position: relative;
  z-index: 2;
  perspective: 2000px;



  &:before {
    content: 'desktop';
    display: none;
  }

  &.is-dropdown-visible {
    .header-dropdown {
      pointer-events: all;
    }

    .header-dropdown-wrapper {
      transform: rotateX(0deg);
      opacity: 1;
    }

    .header-dropdown-container {
      visibility: visible;
      transition: transform .25s, width .25s, height .25s;
    }

    .header-dropdown-background {
      transition: 0.25s;
    }
  }

  &:hover {
    .header-background {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.header-container {
  position: relative;
  z-index: 3;
}

.header-border {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .uk-container {
  }

  .header-border-line {
    opacity: 0.5;
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}

.header-right {
  display: flex;
  flex-direction: column;
}

.header-main {
  order: 2;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  transition: 0.25s;
}

.header-mainnav {
  height: 100%;
  display: flex;
  margin-right: -15px;
}

.header-navitem {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
  transition-duration: .2s;

  @media (min-width: $breakpoint-medium) {
    padding-bottom: 30px;
  }

  &.is-icon {
    padding-left: 15px;
    padding-right: 15px;

    + .is-icon {
      padding-left: 4px;
    }

    @media (min-width: $breakpoint-small) {
      padding-left: 10px;
      padding-left: 15px;

      + .is-icon {
        padding-left: 0px;
      }
    }

    @media (min-width: $breakpoint-medium) {
      padding-left: 15px;

      + .is-icon {
        padding-left: 5px;
      }
    }
  }

  // &.has-dropdown {
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     bottom: 0;
  //     left: 15px;
  //     right: 15px;
  //     background: color(brand, aqua);
  //     height: 3px;
  //     // border-radius: 2px;
  //     transform: scaleX(0);
  //     transform-origin: 0 0;
  //     transition: 0.25s;
  //   }

  //   &.active {
  //     &:after {
  //       transform: scaleX(1);
  //     }

  //     .header-navlink {
  //       color: color(brand, aqua);
  //     }
  //   }

  //   // &:nth-child(1) {
  //   //   &.active {
  //   //     .header-navlink {
  //   //       color: color(brand, blue);
  //   //     }
  //   //   }

  //   //   &:after {
  //   //     background: color(brand, blue);
  //   //   }
  //   // }

  //   // &:nth-child(2) {
  //   //   &.active {
  //   //     .header-navlink {
  //   //       color: color(brand, aqua);
  //   //     }
  //   //   }

  //   //   &:after {
  //   //     background: color(brand, aqua);
  //   //   }
  //   // }

  //   // &:nth-child(3) {
  //   //   &.active {
  //   //     .header-navlink {
  //   //       color: color(brand, green);
  //   //     }
  //   //   }

  //   //   &:after {
  //   //     background: color(brand, green);
  //   //   }
  //   // }

  //   // &:nth-child(4) {
  //   //   &.active {
  //   //     .header-navlink {
  //   //       color: color(brand, purple);
  //   //     }
  //   //   }

  //   //   &:after {
  //   //     background: color(brand, purple);
  //   //   }
  //   // }
  // }

  &:hover {
    .header-navdrop {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.header-navlink {
  padding: 0 8px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 0.8;
  font-family: $fontstack-condensed;
  font-weight: 500;
  color: color(ink, base);
  transition: 0.25s;

  &:hover {
    // color: color(ink, base);
    text-decoration: none;
    span{
      color: color(brand, aqua);
    }
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 22px;
    padding: 0 12px;
  }

  @media (min-width: $breakpoint-large) {
    padding: 0 15px;
    font-size: 33px;
  }
}


.header-util {
  margin: 10px 0 0;
  order: 1;
  display: flex;
  justify-content: flex-end;
  transition: 0.25s;

  @media (min-width: $breakpoint-medium) {
    margin: 20px 0 0;
  }
}

.header-utilnav {
  display: flex;
  transition: 0.25s;

  + .header-utilnav {
    // margin-left: 15px;
    // padding-left: 15px;
    // border-left: 1px solid rgba(#666, 0.6);
  }

  &:last-child {
    margin-right: -10px;
  }
}

.header-utillink {
  position: relative;
  padding: 0 5px;
  font-size: 10px;
  font-family: $fontstack-base;
  text-transform: uppercase;
  font-weight: 400;
  color: #666;
  transition: 0.25s;

  &:hover {
    transition: 0s;
    color: color(brand, aqua);
    text-decoration: none;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 12px;
    font-weight: 600;
    padding: 0 10px;
  }

  @media (min-width: $breakpoint-large) {
    font-size: 13px;
  }
}

.header-navdrop {
  position: absolute;
  margin: 0;
  top: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  background: color(white);
  transition: 0.25s;
  padding: 15px 0;
}

.header-dropdown-wrapper {
  display: block;
  top: 100%;
  width: 100%;
  padding: 0;
  background-color: transparent;
  transform: translateZ(0);
  transform-origin: 100% 0;
  transition: .25s;
  transform: rotateX(-50deg);
  opacity: 0;
}

.header-dropdown-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  transform: translateZ(0);
  transition: visibility .25s;

  .no-csstransitions & {
    display: none;
  }
}

.header-dropdown {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  transition: opacity .25s, visibility .25s;
  z-index: 2;
  overflow: hidden;
  pointer-events: none;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.move-left .header-dropdown-content {
    transform: translateX(-100px);
  }

  &.move-right .header-dropdown-content {
    transform: translateX(100px);
  }
}

.header-dropdown-content {
  padding: 30px 0;
  transition: transform .25s;
  text-align: left;
}

.header-dropdown-menus {
  min-width: 440px;

  @media (min-width: $breakpoint-medium) {
    min-width: 625px;
  }

  @media (min-width: $breakpoint-large) {
    min-width: 762px;
  }
}

.header-dropdown-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  background: color(white);
  transform-origin: top left;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: 0.25s;
  box-shadow: 0 10px 20px rgba(color(ink), 0.1);
}

.dropmenu-menu {
  margin: -6px 0 0;
  padding: 0;
  list-style: none;

  > li {
    margin: 16px 0;
    padding: 0;

    a {
      display: block;
      line-height: 0.8;
      font-size: 18px;
      font-family: $fontstack-condensed;
      font-weight: 500;
      text-transform: uppercase;
      color: color(ink, base);
      transition: 0.25s;

      &:hover {
        transition: 0s;
        color: color(brand, aqua);
        text-decoration: none;
      }

      @media (min-width: $breakpoint-medium) {
        font-size: 20px;
      }

      @media (min-width: $breakpoint-large) {
        font-size: 24px;
      }
    }
  }
}

.dropmenu-heading {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 12px;
  font-family: $fontstack-condensed;
  font-weight: 300;
  text-transform: uppercase;
  color: color(ink, lightest);

  @media (min-width: $breakpoint-medium) {
    font-size: 14px;
  }

  @media (min-width: $breakpoint-large) {
    font-size: 16px;
  }

  &--stories {
    font-family: $fontstack-gastromond;
    text-transform: none;
    color: color(ink);
    font-size: 18px;

    @media (min-width: $breakpoint-medium) {
      font-size: 20px;
    }

    @media (min-width: $breakpoint-large) {
      font-size: 22px;
    }
  }
}

.dropmenu-submenu {
  margin: 5px 0 0;
  padding: 0;
  list-style: none;

  &.is-split {
    @media (min-width: $breakpoint-medium) {
      column-count: 2;
      column-gap: 15px;
    }

    @media (min-width: $breakpoint-large) {
      column-gap: 30px;
    }
  }

  > li {
    margin: 0;
    padding: 0;

    a {
      padding: 2px 0;
      display: block;
      font-size: 12px;
      font-family: $fontstack-base;
      font-weight: 400;
      color: #666;
      transition: 0.25s;

      &:hover {
        transition: 0s;
        color: color(brand, aqua);
        text-decoration: none;
      }

      @media (min-width: $breakpoint-medium) {
        font-size: 14px;
        padding: 4px 0;
      }

      @media (min-width: $breakpoint-large) {
        font-size: 15px;
      }
    }
  }
}

.header-dropdown-cards {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 85%);
    pointer-events: none;
  }
}

.lf-header-menuicon {
  cursor: pointer;
  width: 18px;
  height: 12px;
  display: block;
  position: relative;

  &:hover {
    span {
      height: 17px;
      width: 18px !important;
      background: color(brand, aqua);
    }
  }

  @media (min-width: $breakpoint-medium) {
    width: 24px;
    height: 18px;

    &:hover {
      span {
        width: 24px !important;
      }
    }
  }

  span {
    position: absolute;
    right: 0;
    display: block;
    background: color(ink, base);
    height: 2px;
    border-radius: 1px;
    transition: 0.25s;

    @media (min-width: $breakpoint-medium) {
      height: 3px;
    }

    &:nth-child(1) {
      width: 14px;
      top: 0;

      @media (min-width: $breakpoint-medium) {
        width: 18px;
      }
    }
    &:nth-child(2) {
      width: 10px;
      top: 50%;
      transform: translateY(-50%);

      @media (min-width: $breakpoint-medium) {
        width: 14px;
      }
    }
    &:nth-child(3) {
      width: 18px;
      bottom: 0;

      @media (min-width: $breakpoint-medium) {
        width: 24px;
      }
    }
  }
}

.header-nav-icon {
  margin: 0 0 -2px;
  width: 22px;
  height: 22px;
  display: block;
  fill: color(ink, base);
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    fill: color(brand, aqua);
  }

  @media (min-width: $breakpoint-small) {
    width: 18px;
    height: 18px;
  }

  @media (min-width: $breakpoint-medium) {
    width: 24px;
    height: 24px;
  }
}

.header-navbar.is-transparent:not(.uk-sticky-fixed) {
  &:not(:hover) {
    .header-background {
      opacity: 0;
    }

    .lf-brand {
      path,
      polygon {
        fill: white;
      }
    }

    .header-container {
      a, svg, #current-weather-icon {
        filter: drop-shadow(0 3px 5px rgba(33,43,54,0.35));
      }
    }

    .header-navlink {
      color: color(white)
    }

    .header-utillink {
      color: rgba(color(white), 1);
    }

    .header-utilnav {
      + .header-utilnav {
        border-left-color: rgba(color(white), 0.6);
      }
    }

    .lf-header-menuicon {
      span {
        background: white;
      }
    }

    .header-nav-icon {
      fill: white;
    }
}
}

.header-navbar.is-campaign {
  .lf-brand {
    path,
    polygon {
      fill: white;
    }
  }

  .header-container {
    a, svg, #current-weather-icon {
      filter: drop-shadow(0 3px 5px rgba(33,43,54,0.35));
    }
  }

  .header-navlink {
    color: color(white)
  }

  .header-utillink {
    color: rgba(color(white), 0.8);
  }

  .header-utilnav {
    + .header-utilnav {
      border-left-color: rgba(color(white), 0.6);
    }
  }

  .lf-header-menuicon {
    span {
      background: white;
    }

    &:hover {
      span {
        width: 24px !important;
        background: color(brand, aqua);
      }
    }
  }

  .header-nav-icon {
    fill: white;

    &:hover {
      fill: color(brand, aqua);
    }
  }

  .header-util {
    opacity: 0;
    pointer-events: none;
  }

  .header-navitem {
    &.has-dropdown {
      display: none;
    }
  }

  &:hover {
    .header-background {
      opacity: 0;
      // transform: translateY(0);
    }
  }
}

.header-utilicon {
  position: relative;
  margin: 0 3px -4px 0;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;

  @media (min-width: $breakpoint-medium) {
    width: 17px;
    height: 17px;
    background-size: 17px 17px;
  }

  @media (min-width: $breakpoint-large) {
    width: 17px;
    height: 17px;
  }
}

.header-utilicon--snow {
  background-image: url('../images/icon-snow.svg');
}

.header-utilicon--sun {
  background-image: url('../images/icon-sun.svg');
}

.header-dropdown-cards {
  margin-right: 24px;
}

.header-dropdown-card {
  box-sizing: border-box;
  width: 80%;
  max-width: 160px;

  @media (min-width: $breakpoint-medium) {
    max-width: 180px;
  }

  @media (min-width: $breakpoint-large) {
    max-width: 200px;
  }
}

.header-dropdown-slider {
  margin-top: 3px;

  &.uk-grid {
    margin-left: -16px;

    > * {
      padding-left: 16px;
    }

    @media (min-width: $breakpoint-medium) {
      margin-left: -24px;

      > * {
        padding-left: 24px;
      }
    }
  }
}

.header-dropdown-next {
  z-index: 10;
  margin: -22px 0 0;

  svg {
    margin: 0;
    width: 20px;
    height: 20px;
    fill: color(brand, aqua);

    @media (min-width: $breakpoint-medium) {
      width: 55px;
      height: 26px;
      overflow: visible;
      transform: scale(.5) translateX(29px);
    }
  }
}


.header-small-text {
  color: #B1B1B1;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.88px;
  font-family: $fontstack-condensed;
  line-height: 25px;
}


.lf-top-nav{
  a{
    font-family:$fontstack-base;
    text-transform:uppercase;
    text-decoration:none;
    transition:opacity .3s ease;
    &:hover{
      text-decoration:none;
      opacity:0.6;
    }
  }
}

.lf-upper-nav{
  @media screen and (max-width:$breakpoint-small) {
    display: none !important;
  }
  height:auto !important; 
}

.js-open-megamenu{
  @media screen and (min-width:$breakpoint-small) {
    display: none !important;
  }
}