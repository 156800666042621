.card {
  // padding-right: 12px;
  display: block;
  width: 100%;
  // width: 180px !important;
  cursor: pointer;

  &:hover {
    .card-heading {
      color: color(brand, aqua);

      span {
        background-size: 100% 2px;
      }
    }

    .card-img {
      transform: scale(1.35);
      transition: 15s;
    }
  }
}

.card-image {
  background: color(sky, light);
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: 130%;
  // padding-bottom: 50%;
  // border-radius: 3px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.25s;
  }
}

// .card-img {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   transition: 0.25s;
// }

.card-content {
  margin: 12px 0 0;
}

.card-meta {
  margin: 0 0 2px;
  font-size: 12px;
  font-family: $fontstack-base;
  color: color(ink, lighter);
}

.card-heading {
  margin: 0;
  line-height: 1;
  font-size: 16px;
  font-family: $fontstack-condensed;
  text-transform: uppercase;
  font-weight: 700;
  color: color(ink);

  span {
    background-image: linear-gradient(to right, color(brand, aqua) 0%, color(brand, aqua) 100%);
    background-size: 0px 2px;
    background-position: 0px 90%;
    transition: background-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    padding: 0.1% 0px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 18px;
  }

  @media (min-width: $breakpoint-large) {
    font-size: 20px;
  }
}