.jump {
  display: block;
  overflow: hidden;

  &:hover {
    .jump-image {
      &::after {
        opacity: 0;
      }

      img {
        transform: scale(1.5);
        transition: 20s;
      }
    }
    .jump-content {
      &::before {
        transform: translateX(0);
      }
    }
    .jump-heading {
      transform: translateX(8px);
    }
  }
}

.jump-container {
  position: relative;
}

.jump-content {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 16px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    transform: translateX(-2px);
    right: 100%;
    height: 100%;
    background: color(brand, aqua);
    transition-duration: .25s;
    transition-timing-function: ease-in-out;
  }

  @media (min-width: $breakpoint-small) {
    padding: 16px 24px;

    &::before {
      width: 12px;
      transform: translateX(-4px);
    }
  }

  @media (min-width: $breakpoint-medium) {
    padding: 24px 32px;

    &::before {
      width: 16px;
      transform: translateX(-8px);
    }
  }
}

.jump-heading {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  font-family: $fontstack-condensed;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  position: relative;
  transition-duration: .25s;
  transition-timing-function: ease-in-out;

  @media (min-width: $breakpoint-small) {
    font-size: 26px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 32px;
  }
}

.jump-image {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition: 0.35s;
    background: linear-gradient(to bottom, rgba(33,43,54, 0) 30%, rgba(33,43,54, 0.5) 100%);
  }

  img {
    width: 100%;
    transform: scale(1.015);
    transition: 2s;
  }
}