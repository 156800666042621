.experience-card {
  display: block;

  img {
    width: 100%;
  }

  &:hover {
    .experience-heading {
      color: color(brand, aqua);
      text-decoration: none;

      span {
        background-size: 100% 2px;
      }
    }

    .experience-image {
      img {
        transform: scale(1.5);
        transition: 20s;
      }
    }
  }
}

.experience-container {
  display: flex;
  flex-direction: column;
}

.experience-content {
  order: 2;
  padding-top: 16px;
}

.experience-favourite {
  & > svg {
    transition: 0.2s;
    width: 24px;
    height: 24px;
  }
  & > svg:first-of-type {
    fill: white;
  }
  & > svg:last-of-type {
    fill: transparent;
  }
  &.favourited > svg:last-of-type {
    fill: #D76371;
  }

  &:hover {
    > svg {
      transform: scale(1.1);
      fill: #D76371;
    }
  }
}

.experience-heading {
  margin: 0 0 8px;
  padding: 0;
  line-height: 1.2;
  font-size: 16px;
  font-family: $fontstack-base;
  font-weight: 600;
  transition: 0.35s;

  span {
    background-image: linear-gradient(to right, color(brand, aqua) 0%, color(brand, aqua) 100%);
    background-size: 0px 2px;
    background-position: 0px 95%;
    transition: background-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    padding: 0.1% 0px;
  }

  @media (min-width: $breakpoint-small) {
    font-size: 17px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 18px;
  }
}

.experience-description {
  font-size: 13px;
  line-height: 1.5;

  @media (min-width: $breakpoint-small) {
    font-size: 14px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 15px;
  }
}

.experience-image {
  order: 1;
  position: relative;
  overflow: hidden;

  img {
    transform: scale(1.015);
    transition: 2s;
  }
}
