body {
  &.is-firstrun {
    .intro {
      opacity: 1;
    }

    &.pace-done {
      .intro {
        opacity: 0;
      }
    }
  }

  &:not(.is-firstrun) {
    .intro {
      opacity: 0;
    }
  }

  &.no-js {
    .intro {
      display: none;
    }
  }
}

.intro {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: color(white);
  z-index: 100001;
  pointer-events: none;
  user-select: none;
  transition: 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-brand {
  width: 186px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-left: -7px;
  
  svg {
    width: 100%;
    height: 100%;
  }

  img {
    width: 186px;
    height: 150px;
  }

  @media (min-width: $breakpoint-medium) {
    width: 290px;
    height: 234px;

    img {
      width: 290px;
      height: 234px;
    }
  }
}