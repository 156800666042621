.visit-planner {
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  opacity: 0;
  visiblity: hidden;
  pointer-events: none;
  transform: scale(1.2);
  transition: all 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.is-open {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    visiblity: visible;
    pointer-events: all;
    transition: all 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
    // no
    // & + .page {
    //   overflow: hidden;
    // }
  }
}

.visit-planner-share {
  background: rgb(78, 184, 185);
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition-duration: .2s;

  &:hover {
    opacity: .8;
  }

  & > svg {
    width: 24px;
    height: 24px;
    fill: white;
    filter: drop-shadow(0 0 1px rgba(0,0,0,.1));
  }
  &:nth-of-type(2) > svg {
    width: 18px;
  }
}

.visit-planner-grid {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (min-width: $breakpoint-small) {
    flex-direction: row;
  }

  .visit-planner-left-column {
    width: 100%;
    background: #eee;
    position: relative;
    height: 250px;

    @media (min-width: $breakpoint-small) {
      width: 250px;
      height: 100%;
    }

    @media (min-width: $breakpoint-medium) {
      width: 400px;
    }

    @media (min-width: $breakpoint-large) {
      width: 600px;
    }

    & > #visit-planner-map {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      & .mapboxgl-canvas {
        outline: none !important;
      }
    }
  }
  .visit-planner-right-column {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.visit-planner-container {
  padding: 25px 15px;

  @media (min-width: $breakpoint-small) {
    padding: 40px;
  }
}

.visit-planner-close {
  @extend .megamenu-close;
  opacity: 1;
  z-index: 100 !important;
}

.visit-planner-visit {
  > * {
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 380px) {
      width: 50%;
    }

    @media (min-width: $breakpoint-medium) {
      width: 33.3%;
    }

    @media (min-width: $breakpoint-xlarge) {
      width: 25%;
    }
  }
}

.visit-planner-heading {
  margin: 0 0 8px;
  color: color(ink);
  font-family: $fontstack-heading;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  font-size: 28px;

  @media (min-width: $breakpoint-small) {
    font-size: 36px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 42px;
  }
}

.visit-planner-item {
  & .visit-planner-title {
    margin-top: 8px;
    margin-bottom: 24px;
    line-height: .5;
  }
  & .visit-planner-shareholder {
    font-size: 14px;
    margin: 5px 0 0;
    color: color(brand, aqua);
    display: none;
  }
  & .visit-planner-image, & .visit-planner-placeholder-image {
    // border-radius: 7px;
    overflow: hidden;
  }
  & img {
    object-fit: cover;
    transition-duration: 20s;
  }
  &:hover {
    & img {
      transform: scale(1.5);
    }
    .experience-heading {
      text-decoration: none;

      span {
        background-size: 100% 2px;
      }
    }
  }
  .experience-heading:hover {
    color: color(brand, aqua) !important;
  }
}

.visit-planner-placeholder {
  & .visit-planner-placeholder-image, & .visit-planner-image {
    display: block;
    height: 0;
    padding-bottom: 151.79%;
    position: relative;

    & svg {
      width: 20px;
      height: 20px;
      fill: currentColor;
    }
  }
}

.visit-planner-row {
  // + .visit-planner-row {
  //   margin-top: 12px;

  //   @media (min-width: $breakpoint-small) {
  //     margin-top: 24px;
  //   }

  //   @media (min-width: $breakpoint-medium) {
  //     margin-top: 36px;
  //   }
  // }
  // & .visit-planner-placeholder-image {
  //   border: 1px dashed;
  // }
  // &:first-of-type .visit-planner-placeholder-image {
  //   color: #50E3C2;
  //   border: 1px dashed #50E3C2;
  // }
  // &:nth-of-type(2) .visit-planner-placeholder-image {
  //   color: #F5A623;
  //   border: 1px dashed #F5A623;
  // }
  // &:nth-of-type(3) .visit-planner-placeholder-image {
  //   color: #7ED321;
  //   border: 1px dashed #7ED321;
  // }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.favourited {
  & > svg:first-of-type {
    display: none;
  }
  & > svg:last-of-type {
    animation-name: pulse;
    animation-duration: .4s;
    animation-fill-mode: forwards;
    animation-iteration-count: 2;
  }
}


.visit-planner-hero {

}

.visit-planner-check:empty + .visit-planner-heading {
  display: none;
}

.mapbox-marker {
  background-image: url('/static/images/icon-marker.svg');
  background-size: cover;
  width: 25px;
  height: 25px;
  top: -11px;
  left: -1px;
  cursor: pointer;
}


.visit-planner-empty {
  color: color(ink);
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  margin: 40px auto;
  max-width: 220px;
  line-height: 1.4;
}
