.lf-pricing--recommended {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 3px solid color(blue);
    pointer-events: none;
  }

  &::after {
    content: "Recommended!";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    height: 40px;
    background: color(blue);
    color: color(white);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    pointer-events: none;
  }
}

.lf-pricing-price {
}

.lf-pricing-text {
}

.lf-pricing-feature {
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}