.lf-section-background {
  user-select: none;
}

.lf-section-overlay {
  opacity: 0.8;
}

.lf-section-overlay--black {
  background: color(black);
}

.lf-section-overlay--white {
  background: color(white);
}

.lf-section-videoBackground.flowplayer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;

  .fp-ui {
    display: none;
  }

  .fp-player {
    border-radius: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .fp-mask {
    display: none;
  }

  .fp-engine {
    border-radius: 0;
    object-fit: cover;
  }
}

.section {
  margin: 48px 5px 32px;

  &--spaced {
    margin: 48px 5px;
  }

  &--intro {
    margin: 24px 5px;

    .section-header {
      margin: 0 0 24px;
    }

    @media (min-width: $breakpoint-small) {
      margin: 32px 5px;

      .section-header {
        margin: 0 0 32px;
      }
    }
  }

  &--featurednews {
    &:last-child {
      margin-bottom: 0;

      .section-cover {
        margin-bottom: 0;
      }
    }
  }

  // &:last-child {
  //   margin-bottom: 0;
  // }

  @media (min-width: $breakpoint-small) {
    margin: 64px 5px 48px;

    &--spaced {
      margin: 64px 5px;
    }

    &--intro {
      margin: 48px 5px;

      .section-header {
        margin: 0 0 48px;
      }
    }
  }

  @media (min-width: $breakpoint-medium) {
    margin: 96px 5px 64px;

    &--spaced {
      margin: 96px 5px;
    }

    &--intro {
      margin: 64px 5px;

      .section-header {
        margin: 0 0 64px;
      }
    }
  }
}

.section-header {
  margin: 0 0 32px;
}

.section-heading {
  margin: 0 0 8px;
  font-family: $fontstack-heading;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  font-size: 28px;

  @media (min-width: $breakpoint-small) {
    font-size: 36px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 42px;
  }
}

.section-header-content {
  max-width: 720px;
}

.section-description {
  font-size: $text-lead-font-size;
  color: $text-lead-color;

  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
}

.section-cover {
  margin-bottom: 32px;

  @media (min-width: $breakpoint-small) {
    margin-bottom: 48px;
  }

  @media (min-width: $breakpoint-medium) {
    margin-bottom: 64px;
  }
}

.section-grid-container {
  margin-top: 4px;
}

.section-grid {
  display: grid;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 340px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint-small) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $breakpoint-medium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.section-grid-2 {
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: $breakpoint-medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.section-grid-3 {}

.section-grid-4 {
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: $breakpoint-medium) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.section.section--events {
  // Top-level events section, not just the grid.
  padding-bottom: 100px;
  padding-top: 32px;
  margin-bottom: 0;
  margin-top: 0;
  background-size: cover;
  background-position: bottom;

  @media (min-width: $breakpoint-small) {
    padding-bottom: 200px;
  }

  @media (min-width: $breakpoint-medium) {
    padding-top: 64px;
    padding-bottom: 300px;
  }
}

// .section.section--news {
//   margin-bottom: 150px !important;

//   @media (min-width: $breakpoint-medium) {
//     margin-bottom: 200px !important;
//   }
// }

.section.section--instagram {
  padding-bottom: 1px;
  margin: 0;

  .section-icons--instagram {
    width: calc(100% - 10px);
    font-weight: bold;
    position: absolute;
    margin-top: 5px;

    & > a {
      font-family: $fontstack-heading;
      color: inherit;
      transition-duration: .2s;

      &:hover {
        color: color(brand, aqua);
        text-decoration: none;
        opacity: .8;
      }
      & > svg {
        width: 18px;
        height: 18px;
        margin-right: 2px;
        margin-top: -2px;
        fill: currentColor;
      }
      & > span {
        font-size: 1.15rem;
      }
    }
  }
}

.section-events {
  margin: 0 -15px 0 0;
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // grid-column-gap: 32px;
  // grid-row-gap: 32px;

  // & .slick-track {
  //   margin: 0;
  // }

  @media (min-width: $breakpoint-small) {
    margin: 0 -30px 0 0;
  }

  @media (min-width: $breakpoint-medium) {
    margin: 0 -40px 0 0;
  }

  @media (min-width: $breakpoint-medium) {
    margin: 0;
  }

  .prev {
    width: 55px;
    left: -55px;
    display: none;

    svg {
      overflow: visible;
      transform: scale(.75) scaleX(-1);
    }

    @media (min-width: 1360px) {
      display: block;
    }
  }

  .next {
    width: 55px;
    right: -55px;
    display: none;

    svg {
      overflow: visible;
      transform: scale(.75);
    }

    @media (min-width: 1360px) {
      display: block;
    }
  }
}

.section-instagram {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.section-experiences {
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  @media (min-width: $breakpoint-small) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: $breakpoint-medium) {
    grid-template-columns: repeat(5, 1fr);
  }

  &--fourup {
    grid-template-columns: repeat(4, 1fr);
  }
}

.section-actions {
  margin: 24px 0 0;
}

.section-action {
  color: color(brand, aqua);
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-decoration: none;

  span {
    background-image: linear-gradient(to right, color(brand, aqua) 0%, color(brand, aqua) 100%);
    background-size: 0px 2px;
    background-position: 0px 95%;
    transition: background-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    padding: 0.1% 0px;
  }

  &:hover {
    color: color(brand, aqua);
    text-decoration: none;

    span {
      background-size: 100% 2px;
    }
  }
}
