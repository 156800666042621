@import "fade";
@import "slide";
@import "switch";

.pace {
  display: none;
}

.page {
  width: 100%;
  position: relative;
}

.page-container {
  width: 100%;
  position: absolute;
}

.custom-leave-active {
  .lf-banner-title {
    animation: fadeOutDown 0.5s normal forwards ease-in-out;
  }
  
  .lf-banner-subtitle {
    animation: fadeOutDown 0.5s normal forwards ease-in-out;
    animation-delay: 0.15s;
  }

  .page-content {
    animation: fadeOut 0.5s normal forwards ease-in-out;
    animation-delay: 0.35s;
  }

  .page-content {
    animation: fadeOut 0.5s normal forwards ease-in-out;
    animation-delay: 0.5s;
  }
}

.custom-enter {
  .lf-banner-title,
  .lf-banner-subtitle,
  .page-content {
    opacity: 0;
  }
}

.custom-enter-active {
  .lf-banner-title {
    opacity: 0;
    animation: fadeInDown 0.5s normal forwards ease-in-out;
  }

  .lf-banner-subtitle {
    opacity: 0;
    animation: fadeInDown 0.5s normal forwards ease-in-out;
    animation-delay: 0.15s;
  }

  .page-content {
    opacity: 0;
    animation: fadeIn 0.5s normal forwards ease-in-out;
    animation-delay: 0.35s;
  }

  .page-footer {
    opacity: 0;
    animation: fadeIn 0.5s normal forwards ease-in-out;
    animation-delay: 0.5s;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}