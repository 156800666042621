.weather-condition, .weather-current {
  .icon-01d:before {
    content: "1";
    position: relative;
    top: 4px;
  }

  .icon-02d:before {
    content: "A";
    position: relative;
    top: 2px;
  }

  .icon-03d:before {
    content: "a";
    position: relative;
    top: 2px;
  }

  .icon-04d:before {
    content: "3";
    position: relative;
    top: 2px;
  }

  .icon-09d:before {
    content: "j";
  }

  .icon-10d:before {
    content: "J";
  }

  .icon-11d:before {
    content: "r";
  }

  .icon-13d:before {
    content: "h";
  }

  .icon-50d:before {
    content: "Z";
  }
}

.weather {
  transform-origin: top left;
  margin-top: -30px !important;
  margin-bottom: -30px !important;
  transform: scale(.65);

  @media (min-width: $breakpoint-small) {
    margin-bottom: 0 !important;
    transform: scale(.8);
  }

  @media (min-width: $breakpoint-large) {
    transform: scale(.9);
    transform-origin: top right;
    text-align: center;
    margin-top: 0 !important;
  }
}

#current-weather-icon {
  font-family: weather;
  font-size: 1.5rem;
  font-weight: 400;
  font-style: normal;
  vertical-align: middle;
  margin-right: 3px;

  @media (min-width: $breakpoint-small) {
    margin-right: 5px;
  }

  @media (min-width: $breakpoint-medium) {
    margin-right: 6px;
  }

  &::before {
    position: absolute;
    top: 0;
    line-height: 13px;
    font-size: 22px;

    @media (min-width: $breakpoint-small) {
      line-height: 16px;
      font-size: 24px;
    }

    @media (min-width: $breakpoint-medium) {
      line-height: 17px;
      font-size: 28px;
    }
  }

  &.icon-01d:before {
    content: "1";
    color: #f0e34c;
  }
  &.icon-02d:before {
    content: "A";
    right: -3px;
  }
  &.icon-03d:before {
    content: "a";
    right: -3px;
  }
  &.icon-04d:before {
    content: "3";
    color: color(ink, lightest);
    right: -3px;
  }
  &.icon-09d:before {
    content: "j";
    color: #86adc7;
    right: -3px;
    @media (min-width: $breakpoint-medium) {
      top: -2px;
    }
  }
  &.icon-10d:before {
    content: "J";
    color: #86adc7;
    right: -3px;
    @media (min-width: $breakpoint-medium) {
      top: -2px;
    }
  }
  &.icon-11d:before {
    content: "r";
    color: color(red, base);
    right: -3px;
    top: -2px;
  }
  &.icon-13d:before {
    content: "h";
    color: #6ab6b8;
    right: -3px;
    top: -2px;
  }
  &.icon-50d:before {
    content: "Z";
    color: #86adc7;
    right: -3px;
    top: -2px;
  }
}

#weather i,
#weather-mobile i {
  color: #fff;
  font-family: weather;
  font-size: 1.5rem;
  font-weight: 400;
  font-style: normal;
  vertical-align: middle;
}

.weather-current {
  vertical-align: top;
  display: inline-block;
  width: auto;
  padding: 0 3rem 0 0;
  text-align: center;
}

.weather-current-icon {
  display: inline;
  color: #333;
  font-family: weather;
  font-size: 5rem;
  font-weight: normal;
  font-style: normal;
  line-height: 0.5;
  display: inline;
  position: relative;
  width: 50px;
}

.weather-current-temp {
  color: #333;
  font-size: 3rem;
  font-family: $fontstack-base;
  font-weight: bold;
  display: inline;
  vertical-align: top;
}

.weather-current-condition {
  font-weight: bold;
  font-size: 1.25rem;
  color: #333;
  font-family: $fontstack-base;
  text-align: left;
  margin-top: -15px;
}

.weather-current-wind {
  color: #333;
  display: inline;
  font-family: $fontstack-base;
  font-size: 1rem;
  font-weight: normal;
}

.weather-forecast {
  display: inline-block;
  height: 100px;
  position: relative;
  margin-top: 100px;
  font-family: $fontstack-base;
  font-weight: bold;

  @media (min-width: $breakpoint-large) {
    width: 290px;
  }
}

.weather-condition {
  position: absolute;
  height: 100%;
  top: 0;
  display: block;
  width: 50px;
}

.weather-high {
  font-weight: 300;
  font-size: 13px;
  position: absolute;
  color: #333;
  display: block;
  z-index: 10;
  top: -35px;
  left: 50%;
  text-align: center;
  width: 30px;
  transform: translateX(-50%);
}

.weather-low {
  font-weight: 300;
  font-size: 13px;
  position: absolute;
  color: #333;
  display: block;
  z-index: 10;
  bottom: -35px;
  left: 50%;
  text-align: center;
  width: 30px;
  transform: translateX(-50%);
}

.weather-day {
  position: absolute;
  width: 25px;
  background: #333;
  top: 5px;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);

  &:after {
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #333;
    display: block;
    z-index: 1;
    position: absolute;
    bottom: -12.5px;
  }

  &:before {
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #333;
    display: block;
    z-index: 1;
    position: absolute;
    top: -12.5px;
  }
}

.weather-date {
  font-family: $fontstack-base;
  font-weight: 300;
  font-size: 13px;
  color: #333;
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translateX(-50%);
}

.weather-icon {
  display: inline-block;
  font-family: weather;
  width: 20px;
  height: 20px;
  fill: white;
  overflow: hidden;
  vertical-align: middle;

  @media (max-width: $breakpoint-medium-max) {
    text-align: center;
  }

  svg {
    height: 30px;
    width: 30px;
    position: relative;
    top: -5px;
  }

  .weather-current & {
    color: #333;
    width: 60px;
    height: 60px;
    display: inline;
    left: -8px;
    margin-left: -15px;
    font-size: 60px;
    position: relative;
    @media (max-width: $breakpoint-medium-max) {
      &::before {
        top: -6px !important;
        left: 4px;
      }
    }
  }

  .weather-condition & {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
    width: 40px;
    height: 40px;
    top: -75px;
    transform: translateX(-50%);
    font-size: 30px;
    font-weight: normal;

    svg {
      height: 50px;
      width: 50px;
    }
  }
}
