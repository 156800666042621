//
// Typography settings.
//

//
// Font stacks.
$fontstack-serif:    "Cambria", "Hoefler Text", "Utopia", "Liberation Serif", "Times", "Times New Roman", serif;
$fontstack-sanserif: mallory, "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontstack-mono:     hack, "Consolas", "Liberation Mono", "Courier", monospace;
$fontstack-system:   -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$fontstack-base: "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontstack-label: "acumin-pro-medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontstack-condensed: "acumin-pro-extra-condensed", "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
// $fontstack-heading: "industry", "acumin-pro-extra-condensed", "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontstack-heading: "acumin-pro-extra-condensed", "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontstack-gastromond: 'gastromond', "Cambria", "Hoefler Text", "Utopia", "Liberation Serif", "Times", "Times New Roman", serif;
