body {
  &.is-firstrun {
    &.pace-running {
      .loading {
        opacity: 0;
      }
    }

    &.pace-done {
      .loading {
        opacity: 0;
      }
    }
  }

  &:not(.is-firstrun) {
    &.pace-running {
      .loading {
        opacity: 1;
      }
    }

    &.pace-done {
      .loading {
        opacity: 0;
      }
    }
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: color(sky);
  z-index: 10000;
  pointer-events: none;
  user-select: none;
  transition: 0.35s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  position: relative;
}

.loading-spinner-bounce1, .loading-spinner-bounce2 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: color(ink, lightest);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;  
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.loading-spinner-bounce2 {
  animation-delay: -1.0s;
}