.details-heading {
  margin: 0;
  font-size: 42px;
  line-height: 1;
  text-transform: uppercase;
  font-family: $fontstack-condensed;

  & > .experience-favourite {
    display: inline-flex;
    position: relative;
    margin-left: 10px;
    width: 63px;
    height: 28px;

    @media (min-width: $breakpoint-medium) {
      top: 0;
      margin-left: 10px;
      width: 75px;
      height: 36px;
    }
    & > svg {
      margin: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    & > svg:first-of-type {
      fill: #e0e0e0;
    }
    & > svg:last-of-type {
      left: 0;
    }
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 54px;
  }
}

.details-subheading {
  margin: 5px 0 0;
  font-size: 16px;
  color: color(brand, aqua);

  @media (min-width: $breakpoint-medium) {
    margin: 10px 0 0;
    font-size: 20px;
  }
}

.details-sideheading {
  margin: 0 0 6px;
  padding: 0;
  display: block;
  font-size: 16px;
  font-family: $fontstack-condensed;
  font-weight: 300;
  text-transform: uppercase;
  color: color(ink, lightest);

  @media (min-width: $breakpoint-medium) {
    font-size: 18px;
  }

  @media (min-width: $breakpoint-large) {
    font-size: 20px;
  }
}

.details-content {
  margin-top: 24px;
  margin-bottom: 24px !important;

  @media (min-width: $breakpoint-medium) {
    margin-top: 40px;
    margin-bottom: 40px !important;
  }
}

.detail-gallery {
  .uk-slideshow-items {
    border-radius: 2px;
  }
}

.detail-slideshow-items {
  margin: 0 -20px;
  padding: 0;

  li {
    padding: 0 20px;
  }

  img {
    border-radius: 2px;
    width: 100%;
  }
}

.detail-gallery-left,
.detail-gallery-right {
  svg {
    width: 30px;
    height: 30px;
    overflow: visible;
  }
  & > svg:last-of-type {
    display: none;
  }
}

.detail-gallery-left {
  svg {
    transform: scale(.6) rotate(180deg);
  }
}

.detail-gallery-right {
  svg {
    transform: scale(.6) rotate(0deg);
  }
}

.details-description {
  margin-bottom: 40px !important;
}

.details-date {
  background: white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 13px 15px 15px;
}

.details-meta {
  margin: 24px 0 0;
  padding: 1px 0 0;
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: repeat(2, 1fr);
  // grid-column-gap: 1px;
  // grid-row-gap: 1px;
  // padding-bottom: 1px;
  background: #E8E8E8;

  &:empty {
    display: none;
  }

  &--address {
    margin: 0;

    .details-meta-block {
      margin: 0;
    }
  }
}

.details-meta-block {
  flex: 1;
  margin: 0 0 1px;
  min-width: 99%;
  background: white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  // border-bottom: 1px solid #F3F3F3;

  @media (min-width: $breakpoint-medium) {
    min-width: 48%;
  }

  p {
    display: flex;
    align-items: center;
    margin: 15px 12px;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: color(ink);
    line-height: 1.3;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }

  &.is-full {
    min-width: 100%;

    p {
      // font-size: 20px;
    }
  }

  &.is-extra {
    p {
      strong {
        margin-right: 6px;
        font-weight: 600;
      }

      font-weight: 400;
    }
  }
}

@media (min-width: $breakpoint-medium) {
  .details-meta-block:not(.is-full) + .details-meta-block:not(.is-full) {
    margin-left: 1px;
  }
}

.details-meta-icon {
  margin: 2px 0 0;
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: color(brand, aqua);

  &--left {
    margin-right: 8px;
  }

  &--right {
    margin-left: 8px;
  }
}

.details-meta-price-block {
  background: white;
  box-sizing: border-box;
  padding: 15px 0;
  grid-column-start: 1;
  grid-column-end: 3;

  p {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: color(ink);
    line-height: 1.3;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
}

.details-meta-price {
  font-weight: normal;
}

.details-meta-regular-price {
  font-weight: normal;
  font-style: italic;
  text-decoration: line-through;
  opacity: .3;
  margin-left: 5px;
}

.details-meta-end-date {
  font-size: 14px;
  color: color(brand, aqua);

  & > svg {
    width: 12px;
    height: 12px;
    fill: color(brand, aqua);
    position: relative;
    top: -1px;
  }
}

.details-social-link {
  padding: 10px;
  display: inline-block;
  border: 1px solid;

  & > .details-social-icon {
    margin-right: 0;
    width: 16px;
    height: 16px;
    display: block;
  }

  @media (min-width: $breakpoint-small) {
    padding: 12px;

    & > .details-social-icon {
      width: 20px;
      height: 20px;
    }
  }

  @media (min-width: $breakpoint-medium) {
    padding: 20px;

    & > .details-social-icon {
      width: 30px;
      height: 30px;
    }
  }
}

.details-social {
  color: white;
  font-size: 18px;
  padding: 21px 0;

  @media (min-width: $breakpoint-medium) {
    padding: 21px 5px;
  }
  &:hover {
    color: white;
    text-decoration: none;
  }
}

.details-social-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
  margin-right: 5px;
}

.details-map {
  margin: 12px 0 0;
  height: 0;
  padding-bottom: 56.25%;
  &:focus, & .mapboxgl-canvas:focus {
    outline: none;
  }
}


.details-amenities {
  margin: 24px 0 -12px;
  display: block;

  &--list {
    margin: 15px 12px;

    .details-sideheading {
      margin: 0 0 6px;
      font-size: 16px;
    }

    .details-amenity {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      color: color(ink);
      margin: 0;

      img {
        width: 38px;
        height: 38px;
      }

      + .details-amenity {
        margin-top: 6px;
      }
    }
  }
}

.details-amenity {
  margin: 0;
  display: inline-block;

  img {
    width: 58px;
    height: 58px;
    margin: 0;
    display: block;
  }
}
