.rich-text {
  > *:first-child {
    // margin-top: 20px;
  }

  > *:last-child {
    margin-bottom: 0;
  }



  h2 {
    font-weight: 800;
    font-size: $lf--base-h2-font-size;
    line-height: $lf--base-h2-line-height;

    @media (min-width: $breakpoint-medium) {
      font-size: $lf--base-h2-font-size-m;
      line-height: $lf--base-h2-line-height-m;
    }

    @media (min-width: $breakpoint-large) {
      font-size: $lf--base-h2-font-size-l;
      line-height: $lf--base-h2-line-height-l;
    }
  }
  
  h3 {
    font-weight: 800;
    font-size: $lf--base-h3-font-size;
    line-height: $lf--base-h3-line-height;

    @media (min-width: $breakpoint-medium) {
      font-size: $lf--base-h3-font-size-m;
      line-height: $lf--base-h3-line-height-m;
    }

    @media (min-width: $breakpoint-large) {
      font-size: $lf--base-h3-font-size-l;
      line-height: $lf--base-h3-line-height-l;
    }
  }
  
  h4 {
    font-weight: 600;
    font-size: $lf--base-h4-font-size;
    line-height: $lf--base-h4-line-height;

    @media (min-width: $breakpoint-medium) {
      font-size: $lf--base-h4-font-size-m;
      line-height: $lf--base-h4-line-height-m;
    }

    @media (min-width: $breakpoint-large) {
      font-size: $lf--base-h4-font-size-l;
      line-height: $lf--base-h4-line-height-l;
    }
  }
  
  h5 {
    font-weight: 600;
    font-size: $lf--base-h5-font-size;
    line-height: $lf--base-h5-line-height;

    @media (min-width: $breakpoint-medium) {
      font-size: $lf--base-h5-font-size-m;
      line-height: $lf--base-h5-line-height-m;
    }

    @media (min-width: $breakpoint-large) {
      font-size: $lf--base-h5-font-size-l;
      line-height: $lf--base-h5-line-height-l;
    }
  }
  
  h6 {
    font-weight: 600;
    font-size: $lf--base-h6-font-size;
    line-height: $lf--base-h6-line-height;

    @media (min-width: $breakpoint-medium) {
      font-size: $lf--base-h6-font-size-m;
      line-height: $lf--base-h6-line-height-m;
    }

    @media (min-width: $breakpoint-large) {
      font-size: $lf--base-h6-font-size-l;
      line-height: $lf--base-h6-line-height-l;
    }
  }
}

.lf-richtext > * {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.lf-content-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.newsPost,
.newsListPost,
.newsItineraryPost {
  .page-content > *:first-child {
    padding-top: 24px;

    @media screen and (min-width: $breakpoint-small) {
      padding-top: 42px;
    }

    @media screen and (min-width: $breakpoint-medium) {
      padding-top: 64px;
    }
  }
}

.landing,
.category,
.collection,
.basic,
.bare {
  .page-content .lf-content--001:first-child {
    padding-top: 0;
  }
}