.switch-enter,
.switch-enter-active {
  transform: translateY(200px);
  opacity: 0;
}
.switch-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.switch-leave {
  position: absolute;
  transform: translateY(0);
  opacity: 1;
}
.switch-leave-to {
  position: absolute;
  transform: translateY(200px);
  opacity: 0;
}

.switch-enter-active {
  transition: all 0.45s ease-in;
}
.switch-leave-active {
  transition: all 0.45s ease-out;
}