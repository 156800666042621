.accordion {
  cursor: pointer;
  width: 100%;
  transition-duration: 0.5s;
  & > .accordion-content {
    transition-timing-function: linear;
    overflow: hidden;
    max-height: 420px;
    @media (max-width: $breakpoint-xsmall-max) {
      transition-duration: .75s;
    }
  }
  & > .accordion-panel {
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @media (min-width: $breakpoint-small) {
      cursor: initial;
      user-select: none;
    }

    &:hover > a:last-of-type > span {
      transform: scale(1.75);
    }

    & > a:first-of-type {
      transition-duration: .25s;

      &:hover {
        cursor: text;
        user-select: none;

        @media (max-width: $breakpoint-xsmall-max) {
          color: color(brand, aqua) !important;
          cursor: pointer;
        }
      }
    }

    & > a:last-of-type {
      @media (min-width: $breakpoint-small) {
        display: none;
      }
    }

    & > a:last-of-type > span {
      font-size: 24px;
      padding: 0 8px;
      transition-duration: .5s;
      align-self: flex-end;
      width: 30px;
      height: 30px;
      margin-left: auto;
      display: flex;
      justify-content: center;
      will-change: transform;

      @media (min-width: $breakpoint-small) {
        display: none;
      }
    }
  }
  &.closed {
    & > .accordion-panel {

    }
    & > .accordion-content {
      @media (max-width: $breakpoint-xsmall-max) {
        max-height: 0 !important;
        transition-duration: .75s;
      }
    }
  }
}