.footer {
  border-top: 1px solid #E8E8E8;
  padding: 48px 0;

  @media (min-width: $breakpoint-medium) {
    padding: 64px 0;
  }

  @media (min-width: $breakpoint-large) {
    padding: 96px 0;
  }
}

.footer-top {
  padding-bottom: 64px;
}

.footer-heading {
  margin: 0 0 12px;
  padding: 0;
  color: color(ink);
  font-size: 18px;
  font-weight: 700;
  font-family: $fontstack-condensed;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: $breakpoint-medium) {
    font-size: 24px;
  }

  @media (min-width: $breakpoint-large) {
    font-size: 30px;
  }
}

.footer-nav {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin: 0;
    padding: 0;

    a {
      margin: 0;
      padding: 6px 0;
      display: block;
      font-size: 11px;
      line-height: 1.2;
      font-family: $fontstack-base;
      font-weight: 400;
      color: color(ink, light);
      transition: 0.25s;

      &:hover {
        transition: 0s;
        color: color(brand, aqua);
        text-decoration: none;
      }

      @media (min-width: $breakpoint-medium) {
        font-size: 13px;
      }

      @media (min-width: $breakpoint-large) {
        font-size: 15px;
      }
    }
  }
}

.footer-util.uk-subnav {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin: 0 12px 0 0;
    padding: 0;
    
    @media (min-width: $breakpoint-medium) {
      margin: 0 18px 0 0;
    }

    @media (min-width: $breakpoint-large) {
      margin: 0 24px 0 0;
    }

    > a {
      margin: 0;
      padding: 0;
      display: block;
      font-size: 12px;
      line-height: 1.2;
      font-family: $fontstack-base;
      font-weight: 400;
      color: color(ink, lighter);
      transition: 0.25s;
      text-transform: none;

      &:hover {
        transition: 0s;
        color: color(brand, aqua);
        text-decoration: none;
      }

      @media (min-width: $breakpoint-medium) {
        font-size: 13px;
      }

      @media (min-width: $breakpoint-large) {
        font-size: 15px;
      }
    }
  }
}

.footer-text {
  margin: 8px 0 0;
  font-size: 11px;
  line-height: 1.2;
  font-family: $fontstack-base;
  color: color(ink, lightest);
  text-transform: uppercase;

  a {
    color: color(ink, lightest);
    
    &:hover {
      color: darken(color(brand, aqua), 15%);
    }  
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 12px;
  }

  @media (min-width: $breakpoint-large) {
    font-size: 13px;
  }
}

.footer-text {
  font-weight: 200;
}

.footer-guide {
  a {
    margin: 0;
    // padding: 6px 0;
    line-height: 1.75;
    display: block;
    font-family: $fontstack-base;
    font-weight: bold;
    color: color(brand, aqua);
    transition: 0.25s;

    &:hover {
      transition: 0s;
      color: color(ink, light);
      text-decoration: none;
    }
  }
  .footer-guide-cover {
    max-height: 200px;
    width: auto;
  }

  .section-heading {
    margin: 32px 0 8px;
  }
}

.footer-connect {
  margin-right: 10px;

  .footer-header {
    font-size: 12px;
    text-transform: uppercase;
  }

  .footer-text {
    margin-top: 3px;
    font-size: 11px;
  }

  ul {
    margin-top: 2px;
  }

  @media (min-width: $breakpoint-small) {

  }

  @media (min-width: $breakpoint-medium) {
    margin-right: 15px;

    .footer-header {
      font-size: 16px;
      text-transform: uppercase;
    }

    .footer-text {
      margin-top: 3px;
      font-size: 13px;
    }
  }

  @media (min-width: $breakpoint-large) {

  }
}

.footer-social-icon {
  width: 18px;
  height: 18px;

  @media (min-width: $breakpoint-medium) {
    width: 22px;
    height: 22px;
  }

  @media (min-width: $breakpoint-large) {
    width: 26px;
    height: 26px;
  }
}

.footer-right {
  & svg {
    fill: color(ink, base);
    transition-duration: .2s;
    &:hover {
      fill: color(brand, aqua);
    }
  }
  @media (min-width: $breakpoint-medium) {
    margin-top: -5px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    display: block;
  }
  .uk-iconnav {
    @media (max-width: $breakpoint-xsmall-max) {
      display: flex;
      justify-content: flex-start;
      margin-top: 6px;
      & > li > a {
        margin-left: 4px !important;
      }
      & > li:first-child > a {
        margin-left: 0 !important;
      }
    }
  }
}