.diningcard {
  margin: 4px 0 0;
  padding: 24px;
  display: block;
  background: color(sky, lighter);

  &:hover {
    background: white;
    box-shadow: inset 0 0 0 1px color(brand, aqua);

    .diningcard-heading {
      color: color(brand, aqua);
    }
  }
}

.diningcard-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.diningcard-heading {
  margin: 0;
}