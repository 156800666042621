.hero--base {
  position: relative;
  background: color(ink);
  height: 580px;
  display: flex;
  padding-top: 150px;
  align-items: flex-end;

  @media screen and (min-width: $breakpoint-medium) {
    min-height: 900px;
  }
}

.hero--basic {
  position: relative;
  height: 180px;
  display: flex;
  align-items: flex-end;

  @media screen and (min-width: $breakpoint-medium) {
    height: 280px;
  }
}

.hero--tripplanner {
  position: relative;
  height: 130px;
  display: flex;
  align-items: flex-end;
  background: url('../images/backgrounds/tripplanner.jpg') center no-repeat;
  background-size: cover;

  @media screen and (min-width: $breakpoint-medium) {
    height: 200px;
  }
  
  .hero-contentbox {
    .hero-heading {
      margin-top: 4px;
      font-size: 44px;
      transform: translateY(1px);

      @media (min-width: $breakpoint-small) {
        margin-top: 8px;
        font-size: 64px;
        transform: translateY(2px);
      }

      @media (min-width: $breakpoint-medium) {
        margin-top: 12px;
        font-size: 84px;
        transform: translateY(3px);
      }
    }
  }

  .hero-tripshare {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 10;

    @media (min-width: $breakpoint-small) {
      right: 12px;
    }

    @media (min-width: $breakpoint-medium) {
      right: 12px;
    }
  }

  .hero-tripshare-heading {
    color: white;
    font-family: $fontstack-heading;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;

    @media (min-width: $breakpoint-small) {
      font-size: 15px;
    }

    @media (min-width: $breakpoint-medium) {
      font-size: 16px;
    }
  }
}

.hero--news {
  position: relative;
  background: color(ink);
  height: calc(100vh - 200px);

  @media screen and (min-width: $breakpoint-small) {
    height: calc(100vh - 230px);
  }

  @media screen and (min-width: $breakpoint-medium) {
    height: calc(100vh - 330px);
  }

  @media screen and (orientation: portrait) {
    max-height: 60vh;
  }
}

.hero--spacer {
  min-height: 80px;

  @media screen and (min-width: $breakpoint-medium) {
    min-height: 150px;
  }
}

.hero--large {
  position: relative;
  background: color(ink);
  height: 80vh;
  display: flex;
  padding-top: 150px;
  align-items: flex-end;

  @media screen and (min-width: $breakpoint-medium) {
    min-height: 900px;
  }

  .hero-content {
    // padding-bottom: 96px;
  }
}

.hero--home {
  position: relative;
  background: color(ink);
  height:auto;
  min-height: 560px;
  display: flex;
  padding-top: 150px;
  flex-direction: column;
  @media screen and (min-width:$breakpoint-medium){
    height: 100vh;
  }
  // @media only screen
  //   and (max-device-width: 736px)
  //   and (-webkit-min-device-pixel-ratio: 2)
  //   and (orientation: portrait) {
  //     height: calc(100vh - 75px);
  // }

  // iPhone X
    // @media only screen
    //   and (min-device-width: 375px)
    //   and (max-device-width: 812px)
    //   and (-webkit-min-device-pixel-ratio: 3)
    //   and (orientation: portrait) {
    //     height: calc(100vh - 115px);
    // }

  @media screen and (min-width: $breakpoint-medium) {
    min-height: 900px;
  }

  .slick-slider {
    .slick-prev,
    .slick-next,
    .slick-dots {
      display: none !important;
    }
  }

  .hero-heading {
    // font-size: 10vw;
    line-height: 0.85;

    &--large {
      @include fluid-type(320px, 1240px, 64px, 168px);
    }
  }

  .hero-lines {
    margin: 10px 0 20px;

    @media (min-width: $breakpoint-medium) {
      margin: 20px 0;
    }
  }

  .hero-intro {
    p {
      color: white;
    }
  }
}

.hero-heading {
  font-family: $fontstack-heading;
  font-size: 44px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  text-shadow: 0 3px 5px rgba(33,43,54,0.35);

  @media (min-width: $breakpoint-small) {
    font-size: 84px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 120px;
  }
  @media (min-width: $breakpoint-large) {
    font-size:148px;
  }
}

.hero-intro {
  color: color(white);
  font-size: 16px;
  font-weight: 300;
  text-shadow: 0 3px 5px rgba(33,43,54,0.35);

  @media (min-width: $breakpoint-small) {
    font-size: 20px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 24px;
  }
}

.hero-borders {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}

.hero-border {
  width: 5px;
  height: 5px;
  background: white;
  display: block;
  position: absolute;
  top: 0;
  pointer-events: none;

  &--left {
    height: 100%;
    left: 0;
  }

  &--right {
    height: 100%;
    right: 0;
  }

  &--top {
    width: 100%;
  }

  &--bottom {
    width: 100%;
    top: auto;
    bottom: 0;
  }
}

.hero-nav {
  position: relative;
  z-index: 3;
  width: 100%;
}

.hero-navlist {
  margin: 0 -6px 15px;
  align-items: flex-end;

  @media (min-width: $breakpoint-medium) {
    margin: 0 -15px 42px;
  }
}

.hero-navcontainer {
}

.hero-navitem {
  margin: 0 6px;
  padding: 4px 12px;
  position: relative;
  color: color(white);
  cursor: pointer;
  font-family: $fontstack-base;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  text-shadow: 0 3px 5px rgba(33,43,54,0.35);

  b {
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: 300;
    font-family: $fontstack-heading;
    display: block;
    color: rgba(white, 0.6);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: rgba(white, 0.5);
    transition: 0.25s;
  }

  &:hover {
    &::before {
      background: color(brand, aqua);
    }
  }

  &.uk-active {
    &::before {
      height: calc(100% + 10px);
      background: color(brand, aqua);

      @media (min-width: $breakpoint-medium) {
        height: calc(100% + 37px);
      }
    }
  }

  @media (min-width: $breakpoint-small) {
    margin: 0 12px;
    padding: 4px 18px;
  }

  @media (min-width: $breakpoint-medium) {
    margin: 0 16px;
    padding: 4px 24px;
  }
}

.hero-cover {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.hero-slick {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: color(sky, light);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  &::after {
    // bottom gradient overlay
    background:
      linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 40%),
      linear-gradient(50deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 20%),
      linear-gradient(-50deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 20%);
  }

  &::before {
    // Top gradient overlay
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 15%);
  }

  &.slick-dotted.slick-slider {
    margin: 0;
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    &:first-child {
      z-index: 10;
    }
  }

  .slick-slide,
  .slick-slide > div {
    display: flex !important;
    width: 100%;
  }
}

.hero-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;

  video,
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  video {
    z-index: 2;
  }

  &:first-child {
    z-index: 10;
  }
}

.hero-content {
  width: 100%;
  position: relative;
  z-index: 2;
  flex: 1;
}

.hero-image {
  margin-top: -30px;
  height: 200px;

  @media screen and (min-width: $breakpoint-medium) {
    margin-top: -50px;
    height: 350px;
  }
}

.hero-slides {
  height: 100%;
  position: relative;
}

.hero-slideContent {
  padding-top: 10px;
  @media screen and (min-width:$breakpoint-medium) {
    position: absolute;
    top: 0;
    right: 20px;
    left: 20px;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    padding-bottom: 24px;
    transition: 0s;
    padding-top: 0;
  }
  
  
  
  @media (min-width: $breakpoint-small) {
    left: 35px;
    right: 35px;
  }

  @media (min-width: $breakpoint-medium) {
    left: 40px;
    right: 40px;
    padding-bottom: 50px;
  }

  > .uk-flex {
    height: 100%;
  }

  &.is-active {
    transition: 0.35s;
    opacity: 1;
    pointer-events: all;
  }
}

.hero-scroll {
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 18px;
  height: 30px;
  display: block;
  z-index: 1;
  filter: drop-shadow(3px 0 5px rgba(33,43,54,0.35));

  @media (max-width: 1440px) {
    display: none;
  }
}

.hero-scroll-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 30px;
  display: block;
  background: url('../images/icon-scroll.svg') no-repeat;
}

.hero-scroll-text {
  position: absolute;
  top: -20px;
  left: 8px;
  color: white;
  font-size: 11px;
  font-family: $fontstack-base;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  transform: rotate(-90deg);
  transform-origin: 0 50%;
  white-space: nowrap;
}

.hero-locations {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 20px;
  height: 20px;
  z-index: 1;
  // transform: rotate(-90deg) translateX(100%);
  // transform-origin: 0 50%;
  // filter: drop-shadow(3px 0 5px rgba(33,43,54,0.35));

  @media (max-width: 1440px) {
    display: none;
  }
}

.hero-location {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  z-index: 1;
  filter: drop-shadow(3px 0 5px rgba(33,43,54,0.35));
  display: none;

  &.is-active {
    display: block;
  }
}

.hero-location-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: url('../images/icon-compass.svg') no-repeat;
}

.hero-location-text {
  position: absolute;
  top: -20px;
  left: 10px;
  color: white;
  font-size: 11px;
  font-family: $fontstack-base;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  transform: rotate(-90deg);
  transform-origin: 0 50%;
  white-space: nowrap;

  span {
    margin-left: 5px;
    font-weight: 400;
    font-size: 10px;
  }
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.hero-contentbox {
  display: inline-block;
  min-width: 364px;

  img {
    transform: translateY(15px);
    @media (min-width: $breakpoint-large) {
      transform: translateY(30px);
    }
  }

  .hero-heading {
    margin: 0;
    text-shadow: none;
    font-family: $fontstack-condensed;
    font-size: 82px;
    font-weight: 900;
    transform: translateY(3px);
    
    @media (min-width: $breakpoint-medium) {
      font-size: 124px;
      transform: translateY(6px);
    }

    @media (min-width: $breakpoint-large) {
      font-size: 164px;
      transform: translateY(9px);
    }
  }
}

.hero-action {
  margin: 12px 0 0;
  padding: 0 18px;
  border: 2px solid white;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 26px;
  font-family: $fontstack-heading;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  height: 54px;
  transition: 0.35s;

  &:hover {
    text-decoration: none;
    background: color(brand, aqua);
    border-color: color(brand, aqua);
  }

  .hero-action-text {
    display: block;
    padding: 0 6px 3px;
  }

  .hero-action-icon {
    margin-left: 18px;
    padding: 6px 0 6px 18px;
    // width: 24px;
    // height: 24px;
    display: block;
    border-left: 1px solid white;

    svg {
      width: 18px;
      height: 18px;
      display: block;
      fill: white;
    }
  }

  @media (min-width: $breakpoint-medium) {
    margin: 24px 0 0;
    padding: 0 24px;
    font-size: 32px;
    height: 68px;

    .hero-action-text {
      padding: 0 6px 5px;
    }

    .hero-action-icon {
      margin-left: 24px;
      padding: 10px 0 10px 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}