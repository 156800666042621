.megamenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 0;
  visiblity: hidden;
  pointer-events: none;
  transform: scale(1.2);
  transition: all 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (min-width: $breakpoint-medium) {
    background: rgba(white, 0);
  }

  &.is-open {
    opacity: 1;
    transform: scale(1);
    visiblity: visible;
    pointer-events: all;
    transition: all 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);

    @media (min-width: $breakpoint-medium) {
      background: rgba(white, 1);
    }

    .megamenu-close {
      opacity: 1;
    }

    .megamenu-left,
    .megamenu-utilholder {
      transition: all 0.5s 0.15s;
    }

    .megamenu-right {
      // background: rgba(#ffffff, 0.9);
      transition: all 1s;
    }

    .megamenu-menu {
      > li {
        opacity: 1;
        transform: translateX(0%);
        transition: 0.35s;

        @for $i from 1 through 10{
          &:nth-child(#{$i}) {
            transition-delay: #{($i*.075s)+0.5s};
          }
        }
      }
    }

    .megamenu-util {
      opacity: 1;
      transition: opacity 0.5s 1s;
    }
  }

  &.is-rightactive {
    .megamenu-container {
      overflow: hidden;

      @media (min-width: $breakpoint-small) {
        overflow: auto;
      }
    }
  }
}

.megamenu-background {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('https://images.unsplash.com/photo-1529523432680-4e2a3f0c8ff8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80') center no-repeat white;
  background-size: cover;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(white, 0.9);
    transition: 0.25s;
    opacity: 0;
    pointer-events: none;
  }

  @media (min-width: $breakpoint-small) {
    display: block;
  }
}

.megamenu-container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $breakpoint-small) {
    .megamenu-left {
      width: 300px;
      padding: 100px 35px 20px;
    }

    .megamenu-utilholder {
      width: 300px;
    }

    .megamenu-right {
      left: 300px;
      position: fixed;
    }
  }

  @media (min-width: $breakpoint-medium) {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-template-rows: 1fr 250px;
    grid-template-areas:
          "left right"
          "util util";

    .megamenu-utilholder {
      width: auto;
      padding: 0;

      .uk-flex {
        align-items: center;
      }
    }

    .megamenu-right {
      left: auto;
      position: relative;
    }
  }
}

.megamenu-left {
  flex: 1;
  position: relative;
  z-index: 5;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // width: 450px;
  grid-area: left;
  padding: 100px 35px 50px;
  display: flex;
  // overflow: auto;
  // -webkit-overflow-scrolling: touch;
}

.megamenu-right {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  grid-area: right;
  display: flex;
  flex: 1;
  background: rgba(#ffffff, 0);
  pointer-events: none;

  &.is-active {
    pointer-events: all;
    background: rgba(#ffffff, 1);

    .megamenu-background {
      &::after {
        opacity: 1;
      }
    }

    .megamenu-back {
      opacity: 1;
    }
  }

  @media (min-width: $breakpoint-medium) {
    position: absolute;
  }
}

.megamenu-utilholder {
  grid-area: util;
  background: white;
  display: flex;
  padding: 0 5px 20px;
  align-items: center;

  .uk-container {
    width: 100%;
  }

  @media (min-width: $breakpoint-small) {
    padding: 0 5px 35px;
  }
}

.megamenu-leftholder {
  margin: auto;
  width: 100%;
}

.megamenu-rightholder {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  // padding: 50px 100px;
  pointer-events: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &.is-active {
    pointer-events: all;

    .megamenu-subholder {
      animation: fadeInRight 0.45s normal forwards cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-delay: 0.45s;
    }
  }

  @media (min-width: $breakpoint-small) {

  }
}

.megamenu-subholder {
  margin: auto;
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: fadeOutRight 0.65s normal forwards cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-delay: 0s;
  padding: 0;

  @media (min-width: $breakpoint-small) {
    padding: 60px 35px 35px;
    flex-direction: row;
  }

  @media (min-width: $breakpoint-medium) {
    padding: 100px;
  }
}

.megamenu-colmain {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;

  @media (min-width: $breakpoint-medium) {
    width: 40%;
    margin-right: 48px;
    margin-bottom: 0px;
  }
}

.megamenu-colsub {
  flex: 60%;
}

.megamenu-menu {
  margin: 15px 0;
  padding: 0;

  > li {
    list-style: none;
    margin: 35px 0;
    padding: 0;

    a {
      padding: 5px 0;
      display: inline-block;
      position: relative;
      color: color(ink);
      font-size: 42px;
      font-weight: 700;
      font-family: $fontstack-condensed;
      line-height: 1;
      text-transform: uppercase;

      &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: -5px;
        left: 0;
        height: 3px;
        width: 0;
        background: color(brand, aqua);
        transition: 0.25s;
      }

      &:hover {
        text-decoration: none;

        &::after {
          width: 100%;
        }
      }

      &.is-active {
        &::after {
          width: 60px
        }
      }
    }
  }

  > li {
    opacity: 0;
    transform: translateX(-100px);
    transition: 0;
    transition-timing-function: ease-in-out;
    transition-delay: 0;

    @for $i from 1 through 10{
      &:nth-child(#{$i}) {
        transition-delay: 0;
        transition: 0;
      }
    }
  }
}

.megamenu-util {
  opacity: 0;
  transition: opacity 0s 1s;
  margin: 0;
  padding: 0;

  @media (min-width: $breakpoint-medium) {
    &.is-split-2 {
      column-count: 2;
      column-gap: 30px;
    }

    &.is-split-3 {
      column-count: 3;
      column-gap: 30px;
    }

    &.is-split-4 {
      column-count: 4;
      column-gap: 30px;
    }
  }

  li {
    list-style: none;
    margin: 5px 0;
    padding: 0;

    a {
      display: inline-block;
      position: relative;
      color: #666;
      font-size: 16px;
      font-weight: 400;
      font-family: $fontstack-base;
      line-height: 1;

      &:hover {
        text-decoration: none;
        color: color(brand, aqua);
      }
    }
  }
}

.megamenu-submenu {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 10px 0;
    padding: 0;

    a {
      padding: 5px 0;
      display: inline-block;
      position: relative;
      color: color(ink);
      font-size: 30px;
      font-weight: 500;
      font-family: $fontstack-condensed;
      line-height: 1;
      text-transform: uppercase;

      &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: -5px;
        left: 0;
        height: 3px;
        width: 0;
        background: color(brand, aqua);
        transition: 0.25s;
      }

      &:hover {
        text-decoration: none;

        &::after {
          width: 100%;
        }
      }
    }
  }
}

.megamenu-heading {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: $fontstack-condensed;
  font-weight: 300;
  text-transform: uppercase;
  color: #999;
}

.megamenu-altmenu {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 10px 0;
    padding: 0;

    a {
      display: inline-block;
      position: relative;
      color: #666;
      font-size: 16px;
      font-weight: 300;
      font-family: $fontstack-base;
      line-height: 1;

      &:hover {
        text-decoration: none;
        color: color(brand, aqua);
      }
    }
  }

  @media (min-width: $breakpoint-medium) {
    &.is-split {
      column-count: 2;
      column-gap: 30px;
    }

    li {
      a {
        font-size: 18px;
      }
    }
  }
}

.megamenu-close {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s 0.75s;
  cursor: pointer;
  background: white;

  svg, symbol {
    width: 20px;
    height: 20px;
    fill: color(ink);
  }

  &:hover {
    svg, symbol {
      fill: color(brand, aqua);
    }
  }
}

.megamenu-back {
  display: none;
}

@media (max-width: $breakpoint-medium-max) {
  // .megamenu-left {
  //   width: 400px;
  // }

  // // .megamenu-rightholder {
  // //   left: 500px;
  // //   padding: 50px;
  // // }

  // // .megamenu-subholder {
  // //   flex-direction: column;
  // // }

  // .megamenu-col {
  //   width: 100%;
  // }
}

@media (max-width: $breakpoint-small-max) {
  // .megamenu-left {
  //   width: 280px;
  // }

  // .megamenu-rightholder {
  //   left: 380px;
  //   padding: 50px;
  // }

  // .megamenu-subholder {
  //   flex-direction: column;
  // }

  .megamenu-col {
    width: 100%;
  }

  .megamenu-menu {
    > li {

      a {
        font-size: 32px;
      }
    }
  }

  .megamenu-submenu {
    > li {

      a {
        font-size: 26px;
      }
    }
  }
}

@media (max-width: $breakpoint-xsmall-max) {
  .megamenu-close {
    // position: absolute;
    // top: 20px;
    // left: auto;
    // right: 20px;
    // width: 20px;
    // height: 20px;
    // fill: color(brand, aqua);
    // stroke: color(brand, aqua);

    // svg {
    //   display: block;
    //   width: 20px;
    //   height: 20px;
    // }
  }

  .megamenu-back {
    z-index: 10;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    opacity: 0;
    transition: opacity 0.5s 0.75s;
    fill: color(ink);
    stroke: color(ink);
    cursor: pointer;
    background: rgba(white, 0.95);
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 20px;

    svg {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  .megamenu-left {
    box-sizing: border-box;
    padding: 60px 20px 20px;
    width: 100%;

    &.is-active {
      transform: translateX(-100%) !important;
    }
  }

  .megamenu-utilholder {
    &.is-active {
      transform: translateX(-100%) !important;
    }
  }

  .megamenu-rightholder {
    left: 0;
    padding: 60px 20px 20px;
  }

  // .megamenu-subholder {
  //   flex-direction: column;
  // }

  .megamenu-col {
    width: 100%;
  }

  .megamenu-menu {
    margin: 30px 0 0;
    padding: 0;

    > li {
      list-style: none;
      margin: 15px 0;

      a {
        font-size: 32px;
      }
    }
  }

  .megamenu-util {
    margin: 10px 0;

    li {
      margin: 10px 0;

      a {
        font-size: 14px;
      }
    }
  }

  .megamenu-submenu {
    padding: 0;

    > li {
      margin: 5px 0;

      a {
        font-size: 26px;
      }
    }
  }

  .megamenu-altmenu {
    margin: 15px 0;

    li {
      margin: 10px 0;

      a {
        // font-size: 16px;
      }
    }
  }

  .megamenu-altheading {
    margin: 15px 0 10px;
    font-size: 16px;
  }
}

.megamenu-guide-cover {
  width: 100px;

  @media (min-width: $breakpoint-medium) {
    width: 130px;
  }
}

.megamenu-guide-content {
  padding-left: 12px;

  a {
    margin: 0 0 6px;
    // padding: 6px 0;
    line-height: 1.1;
    display: block;
    font-family: $fontstack-base;
    font-weight: bold;
    color: color(brand, aqua);
    transition: 0.25s;

    &:hover {
      transition: 0s;
      color: color(ink, light);
      text-decoration: none;
    }
  }
}

.megamenu-guide-heading {
  margin: 0 0 10px;
  color: color(ink);
  font-size: 24px;
  font-weight: 700;
  font-family: $fontstack-condensed;
  line-height: 1;
  text-transform: uppercase;
}
