@import "intro";
@import "loading";
@import "header";
@import "banner";
@import "footer";
@import "section";
@import "weather";

@import "accordion";
@import "blog";
@import "card";
@import "detail";
@import "diningcard";
@import "event";
@import "experience";
@import "hero";
@import "megamenu";
@import "visit-planner";
@import "jump";
@import "login";

.uk-button-text {
  font-family: $fontstack-base;
  font-weight: 400;
  text-transform: none;

  span {
    margin-right: 3px;
  }
}

.lf-slider-next,
.lf-slider-prev {
  margin: 0;
  background: rgba(white, 0.9);
  padding: 12px 12px 12px 6px;
  top: 0;
  bottom: 0;
  transform: translateY(0) !important;
  display: flex;
  align-items: center;
  box-shadow: 0 0 24px 24px rgba(white, 0.9);

  svg {
    margin: 0;
    width: 20px;
    height: 20px;
    fill: color(brand, aqua);

    @media (min-width: $breakpoint-medium) {
      width: 26px;
      height: 26px;
    }
  }

  @media (pointer: coarse) {
    display: none !important;
  }
}

.lf-slider-prev {
  left: 0;
  right: auto;

  svg {
    transform: rotate(180deg);
  }
}

.lf-slider-card {
  box-sizing: border-box;
  width: 65%;
  max-width: 100%;

  @media (min-width: $breakpoint-small) {
    width: 32%;
  }

  @media (min-width: $breakpoint-medium) {
    width: 24%;
  }

  @media (min-width: $breakpoint-large) {
    width: 24%;
  }

  @media (min-width: $breakpoint-xlarge) {
    width: 20%;
  }
}

.cardlisting {
  > * {
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 380px) {
      width: 50%;
    }

    @media (min-width: $breakpoint-medium) {
      width: 33.3%;
    }

    @media (min-width: $breakpoint-xlarge) {
      width: 20%;
    }
  }

  &--nofilter {
    > * {
      box-sizing: border-box;
      width: 100%;

      @media (min-width: 340px) {
        width: 50%;
      }

      @media (min-width: $breakpoint-small) {
        width: 33.3%;
      }

      @media (min-width: $breakpoint-medium) {
        width: 25%;
      }

      @media (min-width: $breakpoint-xlarge) {
        width: 20%;
      }
    }
  }
}

.section-events-item {
  box-sizing: border-box;
  width: 85%;
  max-width: 100%;

  @media (min-width: $breakpoint-small) {
    width: 40%;
  }

  @media (min-width: $breakpoint-medium) {
    width: 30%;
  }

  @media (min-width: $breakpoint-large) {
    width: 25%;
  }

  @media (min-width: $breakpoint-xlarge) {
    width: 25%;
  }
}

.backgroudimage {
  margin: -150px 0 -350px;
  height: 500px;
  width: 100%;
  background-position: right center;
  background-size: 50%;
  pointer-events: none;
  float: right;
}

.winter {
  .section.section--events {
    background-image: url('/static/images/backgrounds/winter/events.jpg');
  }

  .footer {
    background: url('/static/images/backgrounds/winter/footer-left.png') center left no-repeat, url('/static/images/backgrounds/winter/footer-right.png') center right no-repeat;
    background-size: 50%;

    @media (min-width: $breakpoint-medium) {
      background-size: 66% !important;
      background: url('/static/images/backgrounds/winter/footer-left.png') top left no-repeat, url('/static/images/backgrounds/winter/footer-right.png') bottom right no-repeat;
    }

    @media (min-width: $breakpoint-xlarge) {
      background: url('/static/images/backgrounds/winter/footer-left.png') 25% left no-repeat, url('/static/images/backgrounds/winter/footer-right.png') center right no-repeat;
      background-size: unset !important;
    }
  }
}

.summer {
  .backgroudimage {
    background-image: url(/static/images/backgrounds/summer/1.jpg);
  }

  .section.section--newshome {
    background: url('/static/images/backgrounds/summer/2.jpg') left 50% no-repeat, url('/static/images/backgrounds/summer/3.jpg') right bottom no-repeat;
    background-size: 40%;
  }

  .section.section--events {
    background-image: url('/static/images/backgrounds/summer/events.jpg');
    background-size: contain;
    padding-bottom: 25%;

    @media (min-width: $breakpoint-small) {
      padding-bottom: 23%;
    }

    @media (min-width: $breakpoint-medium) {
      padding-bottom: 20%;
    }
  }

  .footer {
    background: url('/static/images/backgrounds/summer/footer-left.png') center left no-repeat, url('/static/images/backgrounds/summer/footer-right.png') bottom right no-repeat;
    background-size: 40%;

    @media (min-width: $breakpoint-medium) {
      background-size: 35% !important;
      background: url('/static/images/backgrounds/summer/footer-left.png') center left no-repeat, url('/static/images/backgrounds/summer/footer-right.png') bottom right no-repeat;
    }
    
    @media (min-width: $breakpoint-large) {
      background: url('/static/images/backgrounds/summer/footer-left.png') center left no-repeat, url('/static/images/backgrounds/summer/footer-right.png') bottom right no-repeat;
      background-size: 30% !important;
    }
  }
}