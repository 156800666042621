.event {
  display: block;

  &:hover {
    .event-day,
    .event-month {
      color: color(brand, aqua);
    }

    .event-heading {
      color: color(brand, aqua);
      text-decoration: none;

      span {
        background-size: 100% 2px;
      }
    }

    .event-image {
      img {
        transform: scale(1.5);
        transition: 20s;
      }
    }
  }
}

.event-container {
  display: flex;
  flex-direction: column;
  &.past {
    opacity: .5;
    transition-duration: 1s;
    &:hover {
      opacity: 1;
    }
  }
}

.event-content {
  order: 2;
  padding-top: 24px;
}

.details-heading {
  .event-date {
    margin-bottom: 0;
    .event-day {
      font-size: inherit;
    }
    .event-month {
      font-size: 30px;
    }
  }
}

.event-date {
  margin: 0 0 8px;
  padding: 0;
  line-height: 1;
  text-transform: uppercase;

  .event-day {
    color: color(ink, base);
    font-family: $fontstack-heading;
    font-size: 40px;
    font-weight: 800;
    transition: 0.35s;
  }

  .event-month {
    color: color(ink, base);
    font-family: $fontstack-condensed;
    font-size: 20px;
    font-weight: 300;
    transition: 0.35s;
  }
}

.event-heading {
  margin: 0 0 8px;
  padding: 0;
  line-height: 1.2;
  font-size: 18px;
  font-family: $fontstack-base;
  font-weight: 600;
  transition: 0.35s;

  span {
    background-image: linear-gradient(to right, color(brand, aqua) 0%, color(brand, aqua) 100%);
    background-size: 0px 2px;
    background-position: 0px 95%;
    transition: background-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    padding: 0.1% 0px;
  }
}

.event-description {
  font-size: 15px;
  line-height: 1.5;
}

.event-image {
  order: 1;
  position: relative;
  overflow: hidden;

  img {
    transform: scale(1.015);
    transition: 2s;
  }
}