.lf-account-form-login{
    .uk-checkbox{
        margin-top: 0px !important;
        width:25px;
        height: 25px;
        margin-right: 8px;
    }
    label{
        font-family: $fontstack-label;
    }
}