html.is-noscroll {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

.page-container {
  background: white;
  overflow: hidden;
}

.uk-text-lead {
  p {
    font-weight: 300;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lf-flex-self-middle {
  align-self: center;
}

.lf-content-pullquote {
  & > p:first-of-type {
    font-family: $fontstack-gastromond;
    font-size: 20px;
    line-height: 1.45;

    @media (min-width: $breakpoint-small) {
      font-size: 24px;
    }

    @media (min-width: $breakpoint-medium) {
      font-size: 28px;
    }
  }
  border: 4px solid color(brand, aqua);
  border-left: none;
  border-right: none;

  &--full {
    max-width: 720px;
  }

  &--extra {
    text-align: center;

    & > p:first-of-type {
      font-size: 22px;

      @media (min-width: $breakpoint-small) {
        font-size: 28px;
      }

      @media (min-width: $breakpoint-medium) {
        font-size: 32px;
      }
    }
  }
}



.instagram {
  position: relative;
  padding-bottom: 100%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.shareholdercard {
  margin: 4px 0 0;
  padding: 12px;
  display: block;
  background: color(sky, lighter);

  &:hover {
    background: white;
    box-shadow: inset 0 0 0 1px color(brand, aqua);

    .shareholdercard-heading {
      color: color(brand, aqua);
    }
  }

  @media (min-width: $breakpoint-small) {
    padding: 18px;
  }

  @media (min-width: $breakpoint-medium) {
    padding: 24px;
  }
}

.shareholdercard-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categories-link {
  display: block;
}

.shareholdercard-heading {
  margin: 0;
  font-family: $fontstack-condensed;
  text-transform: uppercase;
}


.listingcards {
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // grid-column-gap: 32px;
  // grid-row-gap: 32px;
}

.filter-group {
  + .filter-group {
    margin-top: 32px;
  }
}

.filter-heading {
  margin: 0 0 8px;
  font-size: 18px;
}

.sticky\@m {
  @media (min-width: $breakpoint-medium) {
    position: sticky;
    top: 155px;
  }
}

.sticky-list {
  z-index: 1;
}
.sticky-list-content-collapse{
  .sticky-list {
    padding-bottom: 60px;
  }
}

.sticky-list-parent {
  margin-bottom: 0 !important;
  height: auto !important;

  @media (max-width: $breakpoint-xsmall-max) {
    padding-left: 10px;
    transform: translateX(10px);
    margin-top: -25px;
  }

  & .sticky-list-container {
    @media (min-width: $breakpoint-small) {
      // position: sticky;
      // top: 155px;
      // height: calc(100vh - 160px);
      // overflow: auto;
    }
  }
}




.newsListing .sticky-list-parent {
  margin-bottom: 0 !important;

  @media (max-width: $breakpoint-xsmall-max) {
    padding-left: 45px;
    transform: translateX(0px);
    margin-top: -35px;
    width: calc(100% - 5px);
  }
}

.newsListing .sticky-list-item {
  @media (max-width: $breakpoint-small-max) {
    padding: 10px 0;
    margin: 0 20px;
    position: relative;
    & > .sticky-list-text {
      font-size: 14px;
    }
    &:last-child {
      padding-bottom: 20px;
    }
  }
}

// Exception because it doesn't have a banner.
.deals .sticky-list-parent, .shareholders .sticky-list-parent, .newsListingFiltered .sticky-list-parent {
  @media (max-width: $breakpoint-xsmall-max) {
    margin-top: 0px;
    padding-left: 30px;
    transform: none;
  }
}

.newsListingFiltered .sticky-list-parent {
  @media (max-width: $breakpoint-xsmall-max) {
    padding-left: 40px;
    width: 100%;
  }
}

.sticky-list-content {
  @extend .uk-section-muted;
}

.sticky-list-content-collapse {
  overflow: hidden;
  transition-duration: .2s;
  @media (max-width: $breakpoint-xsmall-max) {
    height: 0 !important;
  }
}

.sticky-list-heading {
  font-family: $fontstack-heading;
  font-weight: 900;
  font-size: 26px;
  border-left: 8px solid color(brand, aqua);
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 15px;

  @media (max-width: $breakpoint-xsmall-max) {
    & > a {
      display: flex;
      justify-content: space-between;
      user-select: none;
    }
  }

  @media (min-width: $breakpoint-medium) {
    padding: 30px;
    font-size: 36px;
  }

  & .sticky-list-expand {
    transform: translateY(-3px);
  }

  &.sticky-list-heading-filter {
    border-left: none;
    font-family: $fontstack-base;
    text-transform: none;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 22.5px;

    @media (min-width: $breakpoint-medium) {
      font-size: 18px;
      padding: 20px 30px 20px 38px;
    }
  }
}

.sticky-list-filter {
  padding: 5px 0;
  position: relative;
  margin: 0 15px;

  @media (min-width: $breakpoint-medium) {
    margin: 0 30px 0 20px;
  }

  & + .sticky-list-filter {
    padding-top: 0;
  }

  & > a {
    color: $global-emphasis-color !important;
    transition-duration: .2s;
    padding: 10px 7.5px !important;

    @media (min-width: $breakpoint-medium) {
      padding: 10px 20px !important;
    }
    &:hover {
      opacity: .8;
      transform: translateX(3px);
    }
    &[data-active='true'] {
      &:hover {
        opacity: 1;
        color: color(brand, aqua) !important;
        transform: none;
      }
    }
  }

  &.uk-active > a {
    border: 1px solid color(brand, aqua);
    position: relative;

    &:hover {
      color: color(brand, aqua);
    }

    &::after {
      content: "\00D7";
      position: absolute;
      top: 2.5px;
      right: 10px;
      font-size: 1.4rem;
      color: color(brand, aqua);
      @media (min-width: $breakpoint-medium) {
        top: 0;
        right: 14px;
      }
    }
  }

  &:first-child {
    padding-top: 10px;
    &::before {
      content: "";
      position: absolute;
      left: 8px;
      right: 0;
      top: 0;
      height: 1px;
      background: rgba(0,0,0,.1);
      @media (min-width: $breakpoint-medium) {
        left: 18px;
      }
    }
  }
  &:last-child {
    padding-bottom: 15px;

    @media (min-width: $breakpoint-medium) {
      padding-bottom: 30px;
    }
  }
}

.sticky-list-item {
  padding: 20px 0;
  margin: 0 20px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background: rgba(0,0,0,.1);
  }
  &:last-child{
    &::after{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: rgba(0,0,0,.1);
    }
  }
  a{
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  .lf-subcat-dropdown{
    display: none;
    a{
      font-size: 14px;
      color:#757575;
    } 
  }
  .lf-cat-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    a{
      color:#000;
    }
    svg{
      cursor: pointer;
      width:18px;
      height:14px;
      fill:#9F9F9F;
    }
  }
  &.sticky-list-view-more {
    text-align: right;
    padding: 30px 0;
  }
  a {
    transition: all 250ms ease-in-out;
  }
  a:hover {
    transform: translateX(5px);
    opacity: .8;
  }
}

.sticky-list-text {
  font-size: 18px;
  color: $global-emphasis-color;

  & > a {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.sticky-list-subcategory {
  font-weight: 300;
  padding: 7px 0 2px 30px;
  font-size: 85%;
}

.sticky-list-item-heading {
  font-size: 20px;
  font-weight: bold;
  color: $global-emphasis-color;
  line-height: 1.25;
  margin-bottom: 5px;
  & > div {
    padding: 0 10px;
  }
}

.sticky-list-item-length {
  font-size: $global-small-font-size;
  font-style: italic;
  padding: 0 10px;
}

.bannerless {
  margin-top: 80px;

  @media screen and (min-width: $breakpoint-medium) {
    margin-top: 150px;
  }
}

.post {

}

.post-category {
  position: relative;
  z-index: 55;
  color: #007f9c;

  &:hover {
    text-decoration: none;
    color: darken(#007f9c, 10%);
  }
}

.post-image {
  width: 230px;
}

.endless_container {
  width: 100%;
  &::before {
    content: none !important;
  }
}

.endless_more {
  &:hover {
    text-decoration: none;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.endless_loading {
  color: color(brand, aqua);
}

.endless_spinner {
  & > img {
    animation: spin 1s linear infinite;
  }
}

.lf-button-primary {
  border-radius: 2px;
  background-color: color(brand, aqua);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 34px;
  font-family: $fontstack-heading;
  line-height: 1.1;
  height: 74px;
  padding: 0 24px;

  .lf-button-text {
    margin: -2px 0 0;

    em {
      font-size: 24px;
      font-style: normal;
      color: white;
      opacity: 0.8;
    }
  }

  .lf-button-icon {
    padding: 10px 0 10px 24px;
    // width: 24px;
    // height: 24px;
    display: block;
    border-left: 1px solid white;

    svg {
      width: 24px;
      height: 24px;
      display: block;
      fill: white;
    }
  }

  &:hover {
    background: darken(color(brand, aqua), 10%);
  }

  &:active,
  &:focus {
    background: darken(color(brand, aqua), 15%);
  }
}

.lf-button-secondary {
  border-radius: 2px;
  background-color: transparent;
  border: 2px solid color(brand, aqua);
  color: color(brand, aqua);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-family: $fontstack-heading;
  line-height: 1.1;
  height: 58px;
  padding: 0 18px;

  .lf-button-text {
    margin: -2px 0 0;

    em {
      font-size: 18px;
      font-style: normal;
      color: color(brand, aqua);
      opacity: 0.8;
    }
  }

  .lf-button-icon {
    margin-left: 18px;
    padding: 10px 0 10px 18px;
    display: block;
    border-left: 1px solid color(brand, aqua);

    svg {
      width: 18px;
      height: 18px;
      display: block;
      fill: color(brand, aqua);
    }
  }

  &:hover {
    color: white;
    border-color: darken(color(brand, aqua), 10%);
    background: darken(color(brand, aqua), 10%);

    .lf-button-text {
      em {
        color: white;
      }
    }

    .lf-button-icon {
      border-color: white;

      svg {
        fill: white;
      }
    }
  }

  &:active,
  &:focus {
    background: darken(color(brand, aqua), 15%);
  }
}

.lf-button-inline {
  display: inline-flex;
}

.header-navbar.uk-sticky-fixed, .header-navbar:hover {
  .lf-search #search-field::placeholder {
    color: #9a9a9a;
  }
}

.home, .collection, .campaign, .events, .newsPost, .newsListPost, .newsItineraryPost, .newsListing {
  .lf-search #search-field::placeholder {
    color: white;
  }
  .lf-search .algolia-autocomplete .aa-dropdown-menu {
    margin-top: 0;
  }
  .js-search::before {
    bottom: 0px;
  }
}

.lf-search {
  position: relative;
  top: 8px;
  margin-left: 10px;
  transition-duration: .25s;

  @media (max-width: $breakpoint-medium-max) {
    flex: 1;
  }

  &.collapsed {
    margin-left: 0;
  }

  & #search-field {
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: .25s;
    max-width: 275px;
    line-height: 0.8;
    font-family: "acumin-pro-extra-condensed", "acumin-pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 0;
    font-size: 28px;
    z-index: 1000;
    width: 275px !important;
    border: none;
    padding: 0;

    &::-ms-clear {
      display: none;
    }

    &:focus {
      border-color: white;
    }
    &::placeholder {
      transition-duration: .25s;
    }

    &.collapsed {
      max-width: 0;
      @media (max-width: $breakpoint-medium-max) {
        transition-duration: 0s;
      }
    }
  }
}

.header-navbar:not(.is-transparent), .header-navbar.is-transparent:hover, .header-navbar.uk-sticky-fixed {
  .lf-search {
    & input {
      border-color: color(ink, base);
      color: color(ink, base) !important;
      &::placeholder {
        color: #9a9a9a;
      }
      &:focus {
        border-color: color(brand, aqua);
        color: color(ink, base);
      }
    }
  }
}

// This is fucking janky, refactor all of this when you get a chance.
.js-search {
  & > .header-nav-icon {
    z-index: 1000;
  }
  & #search-toggle {
    flex-shrink: 0;
    transition-property: fill;
    & svg:last-of-type, & use:last-of-type, & symbol:last-of-type {
      @media (min-width: $breakpoint-large) {
        transform: translateY(2px);
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    background: #1b1b1b;
    bottom: -5px;
    border-bottom: 1px solid #252525;
    transition-property: opacity;
    opacity: 0;
    z-index: 1;
    pointer-events: none;

    @media (max-width: $breakpoint-medium-max) {
      left: 0 !important;
      right: 0 !important;
      top: 0;
      height: 80px;
      position: fixed;
    }

    @media (min-width: $breakpoint-large) {
      top: 25px;
      left: -17px;
      right: -64px;
    }
  }
  &.opened {
    @media (max-width: $breakpoint-medium-max) {
      position: fixed;
      left: 20px;
      width: calc(100% - 40px);

      &::before {
        opacity: 1;
      }
      & #search-toggle {
        fill: white !important;
        width: 20px;
        height: 20px;
        transform: translateY(-29.5px);
      }
      & #search-field {
        top: -28px;
        color: white !important;
        max-width: 100%;
        width: 100% !important;
      }
    }
    @media (max-width: $breakpoint-small-max) {
      & #search-toggle {
        transform: translateY(-16.5px);
      }
      & #search-field {
        top: -15px;
      }
    }
    @media (max-width: $breakpoint-xsmall-max) {
      & #search-toggle {
        transform: translateY(9.5px);
      }
      & #search-field {
        top: 11px;
      }
    }
    &::after {
      @media (max-width: $breakpoint-medium-max) {
        content: "";
        position: fixed;
        background: #151515;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        height: 100vh;
      }
    }
  }
  &.opened.has-content {
    &::before {
      opacity: 1;
    }
    & #search-toggle {
      fill: white !important;
    }
    & #search-field {
      color: white !important;
    }
  }
}

.header-hide {
  @media (max-width: $breakpoint-medium-max) {
    opacity: 0;
    pointer-events: none;
  }
}

.algolia-autocomplete {
  width: 100%;
  .aa-input, .aa-hint {
    width: 100%;
  }
  .aa-hint {
    color: color(ink, lighter);
  }
  .aa-dropdown-menu {
    left: auto !important;
    position: fixed !important;
    background: #151515;
    padding-bottom: 15px;
    overflow: hidden auto;

    @media (max-width: $breakpoint-medium-max) {
      left: 0 !important;
      right: 0 !important;
      height: calc(100vh - 80px);
      top: 80px !important;
      padding-bottom: 80px;
    }

    @media (min-width: $breakpoint-large) {
      height: calc(100vh - 155px);
      width: 410px;
      transform: translateX(-56px);
      margin-top: 5px;
      top: 150px !important;
    }

    .aa-suggestions-category {
      padding: 20px 20px 0px;
      font-family: $fontstack-heading;
      text-transform: uppercase;
      color: #777;
      letter-spacing: 1px;
      font-size: 14px;
    }
    .aa-suggestion {
      cursor: pointer;
      padding: 5px 20px;
      font-family: $fontstack-heading;
      font-size: 24px;
      text-transform: uppercase;
      color: white;
      transition-duration: .2s;
      line-height: 1.25;

      &.aa-cursor {
        transform: translateX(2px);
        opacity: .8;
      }
      em {
        color: color(brand, aqua);
        font-weight: bold;
        font-style: normal;
      }
    }
  }
}

// Safari hacks
// _:default:not(:root:root), .algolia-autocomplete .aa-dropdown-menu {
//   @media (max-width: $breakpoint-medium-max) {
//     left: -50px !important;
//     right: -30px !important;
//   }
// }
_:default:not(:root:root), #search-field::placeholder {
  position: relative !important;
  top: 9px !important;
}

.listblock-heading {
  position: relative;
  padding: 0;
  font-size: 28px;
  line-height: 1.3;
  font-family: $fontstack-gastromond;
  transition: 0.35s;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  span {
    position: absolute;
    top: -45px;
    left: -30px;
    opacity: 0.1;
    font-size: 110px;
    font-weight: 900;
    font-family: $fontstack-base;
    line-height: 1;
    pointer-events: none;
    color: color(ink);
  }

  @media (min-width: $breakpoint-small) {
    font-size: 32px;

    span {
      font-size: 130px;
      top: -55px;
      left: -40px;
    }
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 36px;

    span {
      font-size: 150px;
    }
  }
}

.listblock-card {
  width: 240px;
  margin: 24px auto 0 0;

  @media (min-width: $breakpoint-small) {
    margin: 0;
  }
}

.itineraryblock-heading {
  position: relative;
  padding: 0;
  font-size: 28px;
  line-height: 1.3;
  font-family: $fontstack-gastromond;
  transition: 0.35s;
  // max-width: 720px;
  // margin-left: auto;
  // margin-right: auto;

  @media (min-width: $breakpoint-small) {
    font-size: 36px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 44px;
  }
}

.itineraryblock-subheading {
  position: relative;
  padding: 0;
  font-size: 20px;
  line-height: 1.3;
  font-family: $fontstack-gastromond;
  transition: 0.35s;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $breakpoint-small) {
    font-size: 24px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 28px;
  }
}

.itineraryblock-day-content {
  .lf-richtext > * {
    margin-left: 0;
  }
}

#iubenda-cs-banner {
  z-index: 100;
  background: color(brand, aqua);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 14px;
  color: white;

  .iubenda-banner-content {
    text-align: left;

    & > p:first-child {
      display: none;
    }
    & > p:last-child {
      margin-top: 0;
      margin-bottom: 0;
      width: calc(100% - 30px);
      @media (min-width: $breakpoint-small) {
      }
    }
  }
  .iubenda-cs-content {
    padding: 20px;
  }
  .iubenda-cs-rationale {
    position: relative;

    @media (max-width: $breakpoint-xsmall-max) {
      text-align: right;
    }
  }
  .iubenda-cs-close-btn {
    position: absolute;
    right: 0;
    font-size: 20px;
    top: -7.5px;
    color: white;

    @media (max-width: $breakpoint-xsmall-max) {
      // position: relative;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.lf-content-image--extra {
  img {
    max-width: none;
  }
}

.block-raw {
  @extend .uk-margin-medium-top;
  @extend .uk-margin-medium-bottom;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 720px;
}

.instagram-media {
  max-width: 720px !important;
  min-width: calc(100% - 2px) !important;
}

.lf-floating-slide-in {
  background-color: white;
  background-image: url("/static/images/backgrounds/footer-left.png");
  background-size: cover;
  background-position: 0 50px;
  box-shadow: 0 5px 15px rgba(0,0,0,.1);
  border-top: 4px solid color(brand, aqua);
  width: auto !important;
  transition-duration: 0s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 40px 40px 40px 35px;
  margin-left: 35px !important;
  transform: translateY(30px) translateZ(0);
  max-width: 335px !important;

  &.uk-active {
    position: fixed;
    visibility: visible;
    transform: translateY(0px) translateZ(0);
    top: initial !important;
    bottom: 92px;
    padding: 40px 40px 40px 35px;
    left: 0;
    opacity: 1;
    transition-duration: .4s;
    transition-property: transform opacity;
    z-index: 10000;
  }

  & + .uk-sticky-placeholder {
    height: 0 !important;
  }

  .lf-floating-slide-in-close {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.5;
    padding: 5px 7.5px;
    border-radius: 4px;
    line-height: 1;
    background: white;
    transition-duration: .2s;
    & > span {
      position: relative;
      top: -1px;
    }
  }

  & form {
    text-align: right;
  }

  & input {
    font-size: 14px;
    border: 1px solid #eee;
    &:focus {
      border: 1px solid color(brand, aqua);
    }
  }

  & .uk-button-text {
    text-transform: none !important;
    letter-spacing: 0;
    &:before {
      content: none;
    }
  }
}

.lf-slide-in-close {
  cursor: pointer;
  &:hover, &:focus, &:active {
    background: #ddd;
    outline: none;
  }
}

.lf-floating-slide-in-mobile {
  background-color: white;
  background-image: url("/static/images/backgrounds/footer-left.png");
  background-size: cover;
  background-position: 0 20px;
  padding: 30px 30px 15px 30px;
  transition-duration: 0s;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(30px) translateZ(0);
  border-top: 2px solid $global-link-color;

  @media (min-width: $breakpoint-small) {
    background-position: 0 -60px;
  }

  &.uk-active {
    position: fixed;
    visibility: visible;
    transform: translateY(0px) translateZ(0);
    top: initial !important;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition-duration: .4s;
    z-index: 100;
  }

  & + .uk-sticky-placeholder {
    height: 0 !important;
  }

  & h2 {
    font-size: 28px !important;
  }

  & form {
    text-align: right;
  }

  & input {
    font-size: 14px;
    border: 1px solid #eee;
    &:focus {
      border: 1px solid color(brand, aqua);
    }
  }

  & .uk-button-text {
    text-transform: none !important;
    letter-spacing: 0;
    &:before {
      content: none;
    }
  }
}

.lf-floating-slide-in-close-mobile {
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  opacity: .5;

  & > span {
    position: relative;
    top: -2px;
    right: -1px;
  }
}

.lf-floating-slide-in-input {
  height: 14px;
  border: 1px solid #B3BDC3 !important;
  width: calc(100% - 22px) !important;
  font-size: 14px !important;

  &::placeholder {
    font-size: 14px !important;
  }
}

.lf-floating-slide-in-mobile-button {
  height: 36px;
  line-height: 30px;
  font-size: 14px;
}

.section--documents {
  padding-top: 6px;

  @media (max-width: $breakpoint-xsmall-max) {
    .footer-guide {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  & .footer-guide-cover {
    box-shadow: -4px 4px 0px rgba(204,204,204,0.5);
  }

  @media (min-width: $breakpoint-medium) {
    padding-top: 38px;
  }
}

.campaign {
  .section--jump-boxes {
    @extend .uk-container;

    .section-grid {
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      @media (min-width: $breakpoint-small) {
        grid-column-gap: 30px;
        grid-row-gap: 30px;
      }
    }

    .section-experiences {
      @media (min-width: $breakpoint-medium) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

.section--weather {
  .section-grid {
    @media (min-width: $breakpoint-small) {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    @media (min-width: $breakpoint-small) {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
    }
  }
}

.section--cta {
  & img {
    object-fit: cover;
  }
  & .uk-button {
    font-family: $fontstack-heading;
    font-weight: bold;
    font-size: 24px;
    height: 44px;
    color: white;
    background: #4EB8B9;
    &:hover {
      background: darken(#4EB8B9, 5%);
    }
  }
  & .uk-padding-large {
    @media (min-width: $breakpoint-small) {
      padding: 40px;
    }
    @media (min-width: $breakpoint-medium) {
      padding: 70px;
    }
  }

  + .section--cta {
    margin-top: -32px;

    @media (min-width: $breakpoint-small) {
      margin-top: -48px;
    }
    @media (min-width: $breakpoint-medium) {
      margin-top: -64px;
    }
  }
}

.embed-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.search-result {
  em {
    color: color(brand, aqua);
    font-weight: bold;
    font-style: normal;
  }
}

.experience-image {
  img[src^='data:image/gif;base64,'] {
    height: 0;
    padding-bottom: 66.667%;
    background: #ddd;
    width: 100%;
  }
}

.staff-content {
  align-self: center;
}

.staff-name {
  text-transform: uppercase;
  font-family: $fontstack-heading;
  font-size: 28px;

  @media (min-width: $breakpoint-small) {
    font-size: 36px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 42px;
  }
}

.staff-position {
  color: color(ink, light);
}

.staff-meta {
  margin-top: 12px;

  p {
    margin: 0;
    font-size: 90%;
    color: color(ink, lighter);
  }
}

.staff-description {
  margin-top: 24px;
}

.newsletter-heading {
  font-family: $fontstack-heading;
  line-height: 1.1;
  text-transform: uppercase;
}


.career-heading {
  font-size: 18px;

  @media (min-width: $breakpoint-small) {
    font-size: 20px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 24px;
  }
}


.cardwriteup-content {
  &.is-cardleft {
    .cardwriteup-container {
      padding-left: 0;

      @media (min-width: $breakpoint-small) {
        padding-left: 20px;
      }

      @media (min-width: $breakpoint-medium) {
        padding-left: 40px;
      }
    }
  }

  &.is-cardright {
    .cardwriteup-container {
      padding-right: 0;

      @media (min-width: $breakpoint-small) {
        padding-right: 20px;
      }

      @media (min-width: $breakpoint-medium) {
        padding-right: 40px;
      }
    }
  }
}

.visitorguide-embed {
  position: relative;
  height: 0;
  padding-bottom: 77%;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
  }
}


.faq-question-heading {
  font-weight: 600;
}


.lf-content-image-container {
  position:  relative;

  .lf-content-image-caption-v2 {
    position: absolute;
    width: 80%;
    color: rgba(255,255,255,0.8);
    text-shadow: 0 1px 1px rgba(0,0,0,0.25);
    // background-color: rgba(0, 0, 0, .5);
    padding: 14px 16px;
    bottom: 0;
    text-align: left;
    font-size: 14px;
    margin: 0;

    &:hover {
      color: rgba(255,255,255,1);
      text-shadow: 0 1px 1px rgba(0,0,0,0.5);
    }
  }
}

.lf-content-image-caption {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right !important;
  font-size: 90%;
  font-style: italic;
}




.hero--medium {
  height: 70vh;
  min-height: 650px;
}

.venture-beyond-hero-container {
  > img {
    max-width: 450px;
    @media screen and (max-width: $breakpoint-medium-max) and (min-width: $breakpoint-small){
      max-width: 350px;
    }
    @media screen and (max-width: $breakpoint-xsmall-max) {
      max-width: 250px;
    }
  }
}

.venture-beyond {
  .hero-content {
    height: 100%;
    .venture-beyond-hero-container {
      position: absolute;
      bottom: 40%;
      transform: translateY(50%);
      left: 20%;

      @media screen and (max-width: $breakpoint-small-max) {
        left: 50%;
        transform: translate(-50%, 50%);
        bottom: 50%;
      }
      > img {
        max-width: 350px;
        @media screen and (max-width: $breakpoint-xsmall-max) {
          max-width: 250px;
        }
      }
    }
  }

  .page-container {
    .uk-container {
      &:first-child {
        .lf-content {
          text-align: center;
          padding-top: 0;
        }
      }

      &:nth-child(2) {
        .lf-content {
          text-align: center;
        }
      }

      & + .uk-container {
        .lf-content {
          padding-top: 0;
        }
      }
    }
  }
}

.lf-venture-beyond-feature {
  height: 600px;

  a {
    text-decoration: none !important;
  }

  @media screen and (max-width: $breakpoint-small-max) {
    height: 500px;
  }

  .lf-feature-content {
    margin-bottom: 100px;
    h1, h2, h3, h4 ,h5 {
      color: white;
      font-size: 40px;
      font-family: $fontstack-gastromond;
    }
    color: white;

    @media screen and (max-width: $breakpoint-small-max) {
      margin-bottom: 25px;
    }
  }

  .lf-feature-background {
    img {
      object-fit: cover;
      width: 100%;
      height: 600px;
      object-position: top;

      @media screen and (max-width: $breakpoint-small-max) {
        height: 500px;
      }
    }
  }

  .lf-icon-featured {
    width: 200px;
    @media screen and (max-width: $breakpoint-small-max) {
      width: 150px;
    }
  }

  & + .lf-venture-beyond-feature {
    margin-top: 5px;
  }
}

.lf-blog-banner-pretext {
  font-family: $fontstack-gastromond;
}


.venture-beyond-post {
  .hero-background {
    img {
      object-position: top;
    }
  }

  .section:nth-last-child(2) {
    margin-bottom: 0;
    .lf-content:last-child {
      padding-bottom: 0;
    }
  }

  .lf-side-content-container {
    position: relative;

    @media screen and (max-width: $breakpoint-medium-max) {
      display: none;
    }

    .lf-side-content {
      position: absolute;
      right: 0;
      width: 200px;
      z-index: 2;
    }
  }
  .lf-venture-beyond-script {
    @media screen and (min-width: $breakpoint-medium) {
      margin: 96px 5px;
    }
  }

  .page-banner {
    @media screen and (max-width: $breakpoint-xsmall-max) {
      .lf-icon-banner {
        display: none;
      }
    }
  }
  .lf-venture-beyond-blog-summary {

    & > div {
      margin: 0 auto;
      max-width: 720px;
    }

    & + .uk-container > .lf-content {
      padding-top: 0;
    }
  }

  .lf-map-with-itinerary-post {
    .lf-itinerary-post-title {
      padding: 40px 50px 20px;
    }
    .lf-itinerary-day-container {
      padding: 10px 50px 10px;

      .lf-itinerary-day-title {
        font-family: $fontstack-condensed;
        transform: rotate(90deg) translatex(40%);
        position: absolute;
        left: 15px;
        top: 0;
      }

      .lf-itinerary-content {
        padding-bottom: 20px;

        .lf-itinerary-activity-title {
          display: flex;
          align-items: center;
          padding-left: 20px;
          color: #000000;
          font-size: 80%;
          @media screen and (max-width: $breakpoint-small-max) {
            font-size: 100%;
          }
        }
        .lf-itinerary-activity-image {
          img {
            width: 100%;
            max-height: 80px;
            object-fit: cover;
            &.lf-default-image {
              object-position: bottom;
            }
          }
        }
      }
    }

    .lf-itinerary-post-link {
      padding: 20px 50px;
      text-align: right;
      a {
        font-weight: 600;
        font-size: 80%;
        @media screen and (max-width: $breakpoint-small-max) {
          font-size: 100%;
        }
      }
    }
  }

  #map {
    height: 100%;
    .mapboxgl-canvas {
      height: 100%;
    }

    .mapboxgl-popup {
      .lf-mapbox-popup-container {
        width: 280px;
        padding: 15px;
        .lf-mapbox-title {
          h6 {
            font-family: $fontstack-gastromond;
            margin-bottom: 5px;
          }
        }
        .lf-mapbox-subtitle {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .lf-mapbox-description {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .lf-mapbox-details {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}


body{
  padding:0 !important;
}
.page-banner , .header-container , .lf-news{
  padding:8px;
}
.section--jump-boxes{
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom:0 !important;
}

.lf-news-sticky{
  @media screen and (min-width:$breakpoint-small){
    padding-left: 30px;
  }
}

.page-banner{
  @media screen and (max-width:$breakpoint-small){
    padding:0 !important;
  }
}