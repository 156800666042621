.blog {
  display: block;
  padding: 12px 0;
  position: relative;
  a{
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  @media (min-width: $breakpoint-small) {
    padding: 24px 0;
  }

  @media (min-width: $breakpoint-medium) {
    padding: 32px 0;
  }

  &:hover {
    .blog-heading {
      color: color(brand, aqua);
      text-decoration: none;

      span {
        background-size: 100% 2px;
      }
    }

    .blog-image {
      img {
        transform: scale(1.5);
        transition: 20s;
      }
    }
  }
}

.blog-banner {
  margin: 0 10px;
}

.blog--intro {
  .blog-heading {
    font-size: 32px;

    span {
      background-image: linear-gradient(to right, color(white) 0%, color(white) 100%);
    }

    @media (min-width: $breakpoint-small) {
      font-size: 42px;
    }

    @media (min-width: $breakpoint-medium) {
      font-size: 52px;
    }
  }

  .blog-author {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }
}

.blog--cover {
  position: relative;
  padding: 0;

  .blog-container {
    @extend .uk-container;
    min-height: calc(80vh - 154px);
    display: flex;
    align-items: flex-end;

    @media (min-width: $breakpoint-medium) {
      min-height: calc(100vh - 154px);
    }
    @media (min-width: $breakpoint-large) {
      min-height: calc(95vh - 154px);
    }
  }

  .blog-content {
    position: relative;
    z-index: 2;
    padding: 32px 0;
    @extend .uk-width-2-3;

    @media (max-width: $breakpoint-small-max) {
      width: 100%;
    }

    @media (min-width: $breakpoint-small) {
      padding: 64px 0;
    }

    @media (min-width: $breakpoint-medium) {
      padding: 128px 0;
    }
  }

  .blog-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    flex-shrink: none;
    overflow: hidden;
    &:after{
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background:rgba(0,0,0,0.3);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: none;
      display: block;
    }
  }

  &.blog--cover--small {
    .blog-container {
      min-height: 45vh;
      padding: 0;
    }
    .blog-content {
      width: 100%;
      padding: 40px;

      @media (min-width: $breakpoint-medium) {
        padding: 80px;
      }
    }
    .blog-heading {
      margin-bottom: 0;
      font-size: 42px;
    }
  }

  .blog-meta {
    color: white;
  }

  .blog-heading {
    color: white;
    font-size: 32px;

    span {
      background-image: linear-gradient(to right, color(white) 0%, color(white) 100%);
    }

    @media (min-width: $breakpoint-small) {
      font-size: 42px;
    }

    @media (min-width: $breakpoint-medium) {
      font-size: 52px;
    }
  }

  .blog-description {
    color: white;
  }

  &:hover {
    .blog-heading {
      color: color(white);

      span {
        background-size: 100% 2px;
      }
    }

    .blog-image {
      img {
        transform: scale(1.25);
        transition: 20s;
      }
    }
  }
}

.blog-container {
  @media (min-width: $breakpoint-small) {
    display: flex;
    align-items: center;
  }
}

.blog-image {
  width: 100%;
  @media (min-width: $breakpoint-small) {
    width: 190px;
  }
  flex-shrink: 0;
  overflow: hidden;
  align-self: flex-start;

  img {
    width: 100%;
    transform: scale(1.015);
    transition: 2s;
    display: block;
  }
}

.blog-link-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}

.blog-content {
  padding: 32px 0 0;
  @media (min-width: $breakpoint-small) {
    padding: 0 0 0 32px;
  }
}

.blog-meta {
  margin: 0 0 8px;
  padding: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  color:#333333;
  @media (min-width: $breakpoint-small) {
    font-size: 14px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 16px;
  }
}

.blog-heading {
  margin: 0 0 16px;
  padding: 0;
  font-size: 22px;
  line-height: 1.3;
  font-family: $fontstack-gastromond;
  transition: 0.35s;

  span {
    background-image: linear-gradient(to right, color(brand, aqua) 0%, color(brand, aqua) 100%);
    background-size: 0px 2px;
    background-position: 0px 98%;
    transition: background-size 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    padding: 0.1% 0px;
  }

  @media (min-width: $breakpoint-small) {
    font-size: 24px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 28px;
  }
}

.blog-description {
  margin: 0;
  padding: 0;

  *:last-child {
    margin-bottom: 0;
  }
}

.blog-grid {
  display: grid;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  grid-template-columns: repeat(3, 1fr);
}

.blog-share-banner {
  padding: 12px;
  display: inline-block;
  float: left;
  margin: 0 5px 0 0;

  & > .blog-share-icon {
    margin-right: 0;
    width: 18px;
    height: 18px;
    display: block;
  }

  @media (min-width: $breakpoint-small) {
    padding: 18px;

    & > .blog-share-icon {
      width: 24px;
      height: 24px;
    }
  }

  @media (min-width: $breakpoint-medium) {
    padding: 20px;

    & > .blog-share-icon {
      width: 30px;
      height: 30px;
    }
  }
}

.blog-share {
  padding: 10px 5px;
  color: white;
  font-size: 14px;
  font-weight: 700;

  @media (min-width: $breakpoint-small) {
    font-size: 16px;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 18px;
    padding: 20px 5px;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.blog-share {
  &:last-of-type {
    &::before {
      content: "copied";
      position: absolute;
      color: black;
    }
  }
}

.blog-share-icon {
  width: 24px;
  height: 24px;
  fill: white;
  margin-right: 5px;
}

.lf-blog-related {
  .uk-h1 {
    margin: 0;
    font-family: $fontstack-heading;
    text-transform: uppercase;
  }
}

.uk-section + .lf-blog-logo {
  padding-top: 0;
}

.lf-blog-logo > .blog-logo-icon {
  height: 45px;
}

.lf-blog-related-articles {
  // Match the height of the blog sharing element + margin.
  margin-bottom: 70px;
  margin-right: 5px;
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 20%;
  z-index: 56;
  transition-duration: .5s;
  transition-property: transform;
  transform: translateX(calc(100% + 5px));

  &.sticky {
    transform: none;
  }
}

.lf-blog-share-container {
  // Must match the height of the fixed element below.
  margin-bottom: 5px;
  min-height: 40px;
  @media (min-width: $breakpoint-large) {
    min-height: 66px;
  }
}

.lf-blog-share {
  width: 100%;
  border: 5px solid white;
  border-top: none;
  border-bottom: none;
  background: white;

  &.sticky {
    bottom: 0;
    z-index: 1;
    position: fixed;
    animation-name: slideUp;
    animation-duration: .2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}


.blog.uk-first-column:first-child{
  padding-top: 0;
}