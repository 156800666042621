.lf-campaign-004,
.lf-campaign-with-form {
  .page-banner {
    position: relative;

    .hero {
      @media screen and (max-width: $breakpoint-small-max) and (orientation: portrait) {
        min-height: 560px;
      }

      @media screen and (max-width: $breakpoint-small-max) and (orientation: landscape) {
        min-height: 500px;
      }

      @media screen and (min-width: $breakpoint-medium) {
        align-items: center;   
      }

      .hero-contentbox {
        @media screen and  (max-width: $breakpoint-small-max) {
          width: 100%;
        }

        .lf-pre-content-icon {
          width: 80px;
          margin-bottom: 20px;

          @media screen and (max-width: $breakpoint-xsmall-max) {
            display: none;
          }
        }

        .hero-heading {
          @media screen and (min-width: $breakpoint-large) {
            font-size: 100px
          }

          @media screen and (max-width: $breakpoint-medium-max) and (min-width: $breakpoint-medium) {
            font-size: 100px
          }
          
          @media screen and  (max-width: $breakpoint-small-max) and (min-width: $breakpoint-small) {
            font-size: 75px;
          }
        }

        p {
          font-size: 18px;
          line-height: 1.5;
          @media screen and (max-width: $breakpoint-small-max) {
            line-height: 1.2;
          }
        }
      }
    }
  }
}

.lf-campaign-004 {
  .page-banner {
    .hero {
      .hero-contentbox {
        @media screen and (min-width: $breakpoint-medium) {
          width: 50%;
        }

        @media screen and  (max-width: $breakpoint-small-max) and (min-width: $breakpoint-small) {
          width: 66.66%;
        }
      }
    }
  }
}

.lf-campaign-with-form {
  .page-banner {
    .hero {
      .hero-contentbox {
        @media screen and (min-width: $breakpoint-large) {
          width: 45%;
        }

        @media screen and  (max-width: $breakpoint-medium-max) and (min-width: $breakpoint-medium) {
          width: 40%;
        }
      }
    }

    .lf-banner-form-container {
      @media screen and (min-width: $breakpoint-medium) {
        position: absolute;
        width: 100%;
        bottom: 50px;
        z-index: 99;
      }

      @media screen and (max-width: $breakpoint-small-max) {
        padding: 30px 0;
      }

      .lf-banner-form {
        background: #ffffff;
        border-top: 5px solid color(brand, aqua);

        @media screen and (min-width: $breakpoint-medium) {
          width: 500px;
          padding: 40px;
          float: right;
        }

        @media screen and  (max-width: $breakpoint-small-max) {
          width: 100%;
          padding: 50px;
        }

        .lf-form-title {
          p, h1, h2, h3, h4, h5, h6 {
            font-family: acumin-pro-extra-condensed,acumin-pro,Helvetica Neue,Helvetica,Arial,sans-serif;
            font-size: 2em;
          }
        }

        .lf-form-title,
        .lf-form-intro {
          margin-bottom: 20px;
        }


        .lf-form-title,
        .lf-form-intro {
          p, h1, h2, h3, h4, h5, h6 {
            color:  color(brand, aqua);
            text-align: left;
          }

          p {
            font-size: 18px;
          }
        }
        
        .lf-field {
          .lf-field-label {
            display: block;
          }

          input {
            width: 100%;
            font-size: 15px;
            border-radius: 0px;
            border: none;
            background: #d7d7d7;
            padding: 10px;

            @media screen and (min-width: $breakpoint-medium) {
              height: 40px;
              margin: 10px auto;
            }
            @media screen and  (max-width: $breakpoint-small-max) {
              height: 50px;
              margin: 15px auto;
            }
          }
        }

        .lf-input-group {
          ul {
            padding: 0;
            margin: 10px 0;
            li {
              list-style: none;
              display: inline;
              padding-right: 20px;
            }
          }
        }

        .lf-hero-action-container {
          margin-top: 20px;
          display: flex;
          width: 100%;

          .hero-action {
            margin: 0;
            background: #ffffff;
            border: 2px solid color(brand, aqua);
            
            .hero-action-icon {
              border-color: color(brand, aqua);
              .lf-button-icon {
                fill: color(brand, aqua);
              }
            }

            h1, h2, h3, h4, h5, h6, p {
              color:  color(brand, aqua);
              font-family: acumin-pro-extra-condensed,acumin-pro,Helvetica Neue,Helvetica,Arial,sans-serif;
            }

            &:hover {
              background: color(brand, aqua);

              .hero-action-icon {
                border-color:#ffffff;
                .lf-button-icon {
                  fill: #ffffff;
                }
              }

              h1, h2, h3, h4, h5, h6, p { 
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}